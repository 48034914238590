import { CandidateChartFormValues } from './types';
import { CHARGING_DURATION_MIN, EXTERNAL_DEPOT_VALUE } from '../SetupChargingEvent/ChargingPowerInputs';
import { ChargingEventPowerMode, ValidateChargingEventResponseVM, VehicleEnhanced } from '@optimization/sa-common';
import { Datum } from 'react-charts';
import { useEffect, useMemo } from 'react';
import { useValidateVehicleChargingEventsMutation } from 'app/services/solution';

export interface ValidateChargingEventsHandler {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  data?: ValidateChargingEventResponseVM;
  reset: () => void;
}

interface Props {
  solutionId: string;
  vehicle: VehicleEnhanced;
  currentDatum: Datum<unknown> | null;
  candidateChartFormValues: CandidateChartFormValues;
  kmAtEvent: number;
  setLoadCandidateChartFormValues: React.Dispatch<React.SetStateAction<boolean>>;
  setOnlyCompleteGenericDailyOdometerPercentageFromValidate: React.Dispatch<React.SetStateAction<boolean>>;
  setCandidateChartFormValues: (value: React.SetStateAction<CandidateChartFormValues>) => void;
}

const useValidateChargingEventsHandler = ({
  solutionId,
  vehicle,
  currentDatum,
  candidateChartFormValues,
  kmAtEvent,
  setLoadCandidateChartFormValues,
  setOnlyCompleteGenericDailyOdometerPercentageFromValidate,
  setCandidateChartFormValues,
}: Props): ValidateChargingEventsHandler => {
  const [validateVehicleChargingEvents, validateVehicleChargingEventsState] =
    useValidateVehicleChargingEventsMutation();

  useEffect(() => {
    if (
      currentDatum &&
      candidateChartFormValues.eventStartTime &&
      candidateChartFormValues.runValidateVehicleChargingEvents
    ) {
      const performanceStep = vehicle.enhanced.vehiclePerformanceStep?.PerformanceStep;

      if (performanceStep) {
        validateVehicleChargingEvents({
          solutionId,
          vehicleId: vehicle.Id,
          KmAtEvent: kmAtEvent,
          PowerMode: ChargingEventPowerMode.Average,
          PerformanceStep: performanceStep,
          TimeAtEvent: candidateChartFormValues.eventStartTime,
          RequestedBatteryLevelAfterCharge: candidateChartFormValues.resultingSoc || undefined,
          ChargingDuration: candidateChartFormValues.includeChargingDurationInValidate
            ? Math.max(candidateChartFormValues.chargingDuration, CHARGING_DURATION_MIN)
            : undefined,
          DepotId:
            candidateChartFormValues.depotIdOrExternal === EXTERNAL_DEPOT_VALUE
              ? undefined
              : Number(candidateChartFormValues.depotIdOrExternal),
        });
      }
    }
  }, [
    currentDatum,
    kmAtEvent,
    solutionId,
    vehicle.Id,
    vehicle.enhanced.vehiclePerformanceStep?.PerformanceStep,
    candidateChartFormValues.includeChargingDurationInValidate,
    candidateChartFormValues.eventStartTime,
    candidateChartFormValues.chargingDuration,
    candidateChartFormValues.resultingSoc,
    candidateChartFormValues.depotIdOrExternal,
    candidateChartFormValues.runValidateVehicleChargingEvents,
    setCandidateChartFormValues,
    validateVehicleChargingEvents,
  ]);

  useEffect(() => {
    if (validateVehicleChargingEventsState.isSuccess) {
      setOnlyCompleteGenericDailyOdometerPercentageFromValidate(
        (onlyCompleteGenericDailyOdometerPercentageFromValidate) => {
          setLoadCandidateChartFormValues(false);

          if (onlyCompleteGenericDailyOdometerPercentageFromValidate) {
            return false;
          } else {
            setCandidateChartFormValues((prev) => ({
              ...prev,
              chargingDuration: validateVehicleChargingEventsState.data.ChargingDuration,
              resultingSoc: validateVehicleChargingEventsState.data.ExpectedBatteryLevelAfterCharging,
              infrastructureProductInstanceId: validateVehicleChargingEventsState.data.InstanceId?.toString() || '',
              runValidateVehicleChargingEvents: false,
              valuesIsLoaded: true,
              slowChargingEventFromValidate: validateVehicleChargingEventsState.data.SlowChargingEvent,
              chargingEventsFromValidate: validateVehicleChargingEventsState.data.ChargingEvents,
            }));
          }

          return onlyCompleteGenericDailyOdometerPercentageFromValidate;
        },
      );
    }
  }, [
    validateVehicleChargingEventsState,
    setLoadCandidateChartFormValues,
    setOnlyCompleteGenericDailyOdometerPercentageFromValidate,
    setCandidateChartFormValues,
  ]);

  return useMemo(
    () => ({
      isLoading: validateVehicleChargingEventsState.isLoading,
      isError: validateVehicleChargingEventsState.isError,
      isSuccess: validateVehicleChargingEventsState.isSuccess,
      data: validateVehicleChargingEventsState.data,
      reset: validateVehicleChargingEventsState.reset,
    }),
    [validateVehicleChargingEventsState],
  );
};

export default useValidateChargingEventsHandler;
