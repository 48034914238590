import { DropdownOption } from '@optimization/ssi-common';
import { TElectrificationPlan } from '../';
import { useMemo } from 'react';

interface Props {
  electrificationPlan: TElectrificationPlan;
}

export const useYearOptions = ({ electrificationPlan }: Props): DropdownOption[] => {
  const yearOptions = useMemo(() => {
    // Find the minimum and maximum performance steps
    let minimumPerformanceStep = Infinity;
    let maximumPerformanceStep = -Infinity;

    electrificationPlan.forEach((item) => {
      Object.keys(item.vehicles).forEach((vehicleId) => {
        const step = parseInt(item.vehicles[vehicleId].minimumPerformanceStep, 10);

        minimumPerformanceStep = Math.min(minimumPerformanceStep, step);
        maximumPerformanceStep = Math.max(maximumPerformanceStep, step);
      });
    });

    const performanceStepDifference = maximumPerformanceStep - minimumPerformanceStep;

    // Calculate the range
    const range = performanceStepDifference + 1;

    // Determine the number of years to display
    const numberOfYears = Math.max(range, 10 - performanceStepDifference);

    // Generate the options
    return Array.from({ length: numberOfYears }, (_, index) => {
      const yearsCount = performanceStepDifference + index + 1;

      return {
        name: `${yearsCount} year${yearsCount === 1 ? '' : 's'}`,
        value: yearsCount,
      };
    });
  }, [electrificationPlan]);

  return yearOptions;
};

export default useYearOptions;
