import classes from './style.module.scss';
import classNames from 'classnames';
import SliderWrapper from './SliderWrapper';
import useOnChangeNumberOfYears from './utils/useOnChangeNumberOfYears';
import useOnChangeSlider from './utils/useOnChangeSlider';
import useSelectedYearsCount from './utils/useSelectedYearsCount';
import useYearOptions from './utils/useYearOptions';
import { Accordion } from '@optimization/ssi-common';
import { Dropdown, KeyedString } from '@optimization/ssi-common';
import { SetVehiclesByPerformanceStepByNumberOfYears } from './utils/useVehiclesByPerformanceStep';
import { useCallback, useState } from 'react';

export type ElectrificationPlanVehicle = {
  minimumPerformanceStep: string;
};

export type ElectrificationPlanItem = {
  performanceStep: string;
  vehicles: { [vehicleId: string]: ElectrificationPlanVehicle };
  modified: boolean; // To know when to go on with modifying the next slider
};

export type TElectrificationPlan = ElectrificationPlanItem[];

export type VehiclesByPerformanceStep = { [performanceStep: string]: number[] };

interface Props {
  className?: string;
  electrificationPlan: TElectrificationPlan;
  electrificationPlanVehicleCount: number;
  vehiclesByPerformanceStep: VehiclesByPerformanceStep;
  minimumPerformanceStepByVehicle: KeyedString;
  setElectrificationPlan: React.Dispatch<React.SetStateAction<TElectrificationPlan>>;
  setVehiclesByPerformanceStepByNumberOfYears: SetVehiclesByPerformanceStepByNumberOfYears;
}

const ElectrificationPlan = ({
  className,
  electrificationPlan,
  electrificationPlanVehicleCount,
  vehiclesByPerformanceStep,
  minimumPerformanceStepByVehicle,
  setElectrificationPlan,
  setVehiclesByPerformanceStepByNumberOfYears,
}: Props) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const onChangeSlider = useOnChangeSlider({
    vehiclesByPerformanceStep,
    minimumPerformanceStepByVehicle,
    electrificationPlanVehicleCount,
    setElectrificationPlan,
    setVehiclesByPerformanceStepByNumberOfYears,
  });

  const onChangeNumberOfYears = useOnChangeNumberOfYears({
    setVehiclesByPerformanceStepByNumberOfYears,
  });

  const yearOptions = useYearOptions({ electrificationPlan });

  const selectedYearsCount = useSelectedYearsCount({ electrificationPlan });

  const toggleAccordion = useCallback(() => {
    setIsAccordionExpanded((prev) => !prev);
  }, []);

  return (
    <div className={classNames(classes['electrification-plan'], className)}>
      <Accordion
        hideLastBorder
        items={[
          {
            header: 'Electrification plan',
            expanded: isAccordionExpanded,
            onToggle: toggleAccordion,
            children: (
              <div className={classes.content}>
                <div className={classNames(classes.fields, classes.gap)}>
                  <div>Performance steps</div>
                  <Dropdown
                    value={selectedYearsCount}
                    helper={`${yearOptions[0].value}-${yearOptions[yearOptions.length - 1].value} year plan`}
                    options={yearOptions}
                    onChange={onChangeNumberOfYears}
                  />
                </div>
                <div className={classNames(classes.sliders)}>
                  {electrificationPlan.map((electrificationPlanItem) => (
                    <SliderWrapper
                      key={electrificationPlanItem.performanceStep}
                      electrificationPlanItem={electrificationPlanItem}
                      electrificationPlanVehicleCount={electrificationPlanVehicleCount}
                      sliderValue={Object.keys(electrificationPlanItem.vehicles).length.toString()}
                      onChangeSlider={onChangeSlider}
                    />
                  ))}
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ElectrificationPlan;
