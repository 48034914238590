import classes from './style.module.scss';
import classNames from 'classnames';
import { Accordion, Dropdown, RadioButtons, Toggle } from '@optimization/ssi-common';
import { TdsDivider } from '@scania/tegel-react';
import { TimeWindow } from '@optimization/sa-common';
import { useCallback, useMemo, useState } from 'react';
import {} from '@optimization/ssi-common';

export interface IEvaluationSettings {
  evaluateChassisAdaptation: boolean;
  evaluateWheelConfiguration: boolean;
  evaluateAxleDistance: boolean;
  evaluateChassisHeight: boolean;
  evaluateCabType: boolean;
  evaluateDailyDistance: string;
  evaluateMaxGtw: string;
  timeWindow: TimeWindow;
}

interface Props {
  className?: string;
  evaluationSettings: IEvaluationSettings;
  setEvaluationSettings: React.Dispatch<React.SetStateAction<IEvaluationSettings>>;
}

const EvaluationSettings = ({ className, evaluationSettings, setEvaluationSettings }: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const onChangeToggle = useCallback(
    (settingKey: keyof IEvaluationSettings) => (checked: boolean) => {
      setEvaluationSettings((prev) => ({ ...prev, [settingKey]: checked }));
    },
    [setEvaluationSettings],
  );

  const onChangeRadio = useCallback(
    (settingKey: 'evaluateDailyDistance' | 'evaluateMaxGtw') => (value: string) => {
      setEvaluationSettings((prev) => ({ ...prev, [settingKey]: value }));
    },
    [setEvaluationSettings],
  );

  const onChangeTimeWindow = useCallback(
    (value: string) => {
      setEvaluationSettings((prev) => ({ ...prev, timeWindow: value as unknown as TimeWindow }));
    },
    [setEvaluationSettings],
  );

  const timeWindowOptions = useMemo(
    () => [
      { name: 'Lifetime', value: TimeWindow.Lifetime },
      { name: 'One year', value: TimeWindow.OneYear },
      { name: 'Six months', value: TimeWindow.SixMonths },
      { name: 'One month', value: TimeWindow.OneMonth },
    ],
    [],
  );

  const distanceOptions = useMemo(
    () => [
      {
        name: 'Median day',
        value: 'P50',
        checked: evaluationSettings.evaluateDailyDistance === 'P50',
      },
      { name: 'Longer day', value: 'P75', checked: evaluationSettings.evaluateDailyDistance === 'P75' },
      { name: 'Longest day', value: 'P95', checked: evaluationSettings.evaluateDailyDistance === 'P95' },
    ],
    [evaluationSettings.evaluateDailyDistance],
  );

  const gtwOptions = useMemo(
    () => [
      { name: 'Median day', value: 'P50', checked: evaluationSettings.evaluateMaxGtw === 'P50' },
      { name: 'Heavier day', value: 'P75', checked: evaluationSettings.evaluateMaxGtw === 'P75' },
      { name: 'Heaviest day', value: 'P95', checked: evaluationSettings.evaluateMaxGtw === 'P95' },
    ],
    [evaluationSettings.evaluateMaxGtw],
  );

  return (
    <div className={classNames(classes['evaluation-settings'], className)}>
      <Accordion
        hideLastBorder
        items={[
          {
            header: 'Evaluation settings',
            expanded: isExpanded,
            onToggle: handleToggle,
            children: (
              <div className={classes.content}>
                <div className={classNames(classes.fields, classes.gap)}>
                  <div>Time frame</div>
                  <Dropdown
                    value={evaluationSettings.timeWindow}
                    placeholder="Select an option..."
                    helper="Operation during specific time frame"
                    options={timeWindowOptions}
                    onChange={onChangeTimeWindow}
                  />
                </div>
                <TdsDivider />
                <div className={classNames(classes.fields, classes.gap)}>
                  <div>Chassis adaptation</div>
                  <Toggle
                    checked={evaluationSettings.evaluateChassisAdaptation}
                    onChange={onChangeToggle('evaluateChassisAdaptation')}
                  />
                  <div>Wheel configuration</div>
                  <Toggle
                    checked={evaluationSettings.evaluateWheelConfiguration}
                    onChange={onChangeToggle('evaluateWheelConfiguration')}
                  />
                  <div>Axle distance</div>
                  <Toggle
                    checked={evaluationSettings.evaluateAxleDistance}
                    onChange={onChangeToggle('evaluateAxleDistance')}
                  />
                  <div>Chassis height</div>
                  <Toggle
                    checked={evaluationSettings.evaluateChassisHeight}
                    onChange={onChangeToggle('evaluateChassisHeight')}
                  />
                  <div>Cab type</div>
                  <Toggle checked={evaluationSettings.evaluateCabType} onChange={onChangeToggle('evaluateCabType')} />
                </div>
                <TdsDivider />
                <div className={classes.radios}>
                  <div>
                    <div>Daily distance</div>
                    <RadioButtons
                      className="mt-spacing-4"
                      onChange={onChangeRadio('evaluateDailyDistance')}
                      options={distanceOptions}
                    />
                  </div>
                  <div>
                    <div>Max GTW</div>
                    <RadioButtons
                      className="mt-spacing-4"
                      onChange={onChangeRadio('evaluateMaxGtw')}
                      options={gtwOptions}
                    />
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default EvaluationSettings;
