import classes from './style.module.scss';
import classNames from 'classnames';
import ensureClickableSecondary from '../utils/ensureClickableSecondary';
import useTooltip from './utils/useTooltip';
import { AxisOptions, Datum } from 'react-charts';
import { CandidateChartFormValues } from '../utils/types';
import { useCallback, useMemo } from 'react';
import { ValidateChargingEventsHandler } from '../utils/useValidateChargingEventsHandler';
import {
  CandidateChargingGraph,
  ChargingEventV2VM,
  DataPoint,
  VehicleEnhanced,
  ChartChargingEvent,
  GetCandidateChargingTime,
} from '@optimization/sa-common';

interface Props {
  clickableChartChargingEvents: ChartChargingEvent[];
  distanceFromSavedChargingEvents: DataPoint[];
  distanceIncludingPreview: DataPoint[];
  dailyRange: number;
  vehicle: VehicleEnhanced;
  currentDatum: Datum<unknown> | null;
  candidateChartFormValues: CandidateChartFormValues;
  genericDailyOdometerKm: number;
  validateChargingEventsHandler: ValidateChargingEventsHandler;
  setCurrentDatumIsAdjustedByCurrentChargingEvent: React.Dispatch<React.SetStateAction<boolean>>;
  findSavedChargingEvent: (distance: number) => ChargingEventV2VM | undefined;
  setCurrentDatum: React.Dispatch<React.SetStateAction<Datum<unknown> | null>>;
  findChartChargingEvent: (primaryValue: number) => ChartChargingEvent | undefined;
  setLoadCandidateChartFormValues: React.Dispatch<React.SetStateAction<boolean>>;
  setChartHasBeenClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentDatumTimeAtEvent: React.Dispatch<React.SetStateAction<string>>;
  getCandidateChargingTime: GetCandidateChargingTime;
  resetCandidateChartFormValues: () => void;
}

const CandidateGraph = ({
  clickableChartChargingEvents,
  distanceFromSavedChargingEvents,
  distanceIncludingPreview,
  dailyRange,
  vehicle,
  currentDatum,
  candidateChartFormValues,
  genericDailyOdometerKm,
  validateChargingEventsHandler,
  setCurrentDatumIsAdjustedByCurrentChargingEvent,
  findSavedChargingEvent,
  setCurrentDatum,
  getCandidateChargingTime,
  findChartChargingEvent,
  setLoadCandidateChartFormValues,
  setChartHasBeenClicked,
  setCurrentDatumTimeAtEvent,
  resetCandidateChartFormValues,
}: Props) => {
  const primaryAxis = useMemo<Partial<AxisOptions<DataPoint>>>(
    () => ({
      getValue: (datum) => datum.primary,
      max: genericDailyOdometerKm,
      hardMax: genericDailyOdometerKm,
      position: 'bottom',
      formatters: {
        scale: () => '',
      },
    }),
    [genericDailyOdometerKm],
  );

  const secondaryAxes = useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        // SoC area
        getValue: (datum) => datum.secondary,
        id: '1',
        position: 'left',
        max: 100,
        hardMax: 100,
        min: 0,
        hardMin: 0,
        stacked: false,
        elementType: 'area',
        formatters: {
          scale: (value: number) => `${value}%`,
        },
      },
      // Safety margin
      {
        getValue: (datum) => datum.secondary,
        id: '2',
        max: 100,
        hardMax: 100,
        min: 0,
        hardMin: 0,
        elementType: 'line',
        stacked: false,
        show: false,
        position: 'left',
      },
      // Charging events
      {
        getValue: (datum) => datum.secondary,
        id: '3',
        max: 100,
        hardMax: 100,
        min: 0,
        hardMin: 0,
        elementType: 'bubble',
        show: false,
        position: 'left',
      },
      {
        getValue: (datum) => datum.secondary,
        id: '4',
        max: 100,
        hardMax: 100,
        min: 0,
        hardMin: 0,
        elementType: 'bar',
        show: false,
        position: 'left',
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        label: 'Base demand',
        secondaryAxisId: '1',
        data: distanceIncludingPreview,
      },
      {
        label: 'Safety margin',
        secondaryAxisId: '2',
        data: [
          {
            primary: 0,
            secondary: (30 * 100) / dailyRange,
          },
          {
            primary: genericDailyOdometerKm,
            secondary: (30 * 100) / dailyRange,
          },
        ],
      },
      {
        label: 'Charging event',
        secondaryAxisId: '3',
        data: clickableChartChargingEvents,
      },
      {
        label: 'Charging event set up',
        secondaryAxisId: '3',
        data: currentDatum
          ? [
              {
                primary: currentDatum.primaryValue,
                secondary: currentDatum.secondaryValue,
              },
            ]
          : [],
      },
    ],
    [distanceIncludingPreview, dailyRange, genericDailyOdometerKm, clickableChartChargingEvents, currentDatum],
  );

  const tooltip = useTooltip({
    departureTime: candidateChartFormValues.departureTime,
    findChartChargingEvent,
    getCandidateChargingTime,
  });

  const onClickDatum = useCallback(
    (datum: Datum<unknown> | null) => {
      if (datum) {
        let primary = -1;
        let secondary = -1;

        // To make sure that only charging events that are saved in the database are clickable
        const savedChargingEvent = findSavedChargingEvent(datum.primaryValue);

        if (savedChargingEvent) {
          primary = savedChargingEvent.KmAtEvent;
          secondary = ensureClickableSecondary({
            batteryLevelBeforeCharging: savedChargingEvent.BatteryLevelBeforeCharging,
          });
        } else {
          // To make sure that only data real data points that not are preview are clickable
          const dataPoint = distanceFromSavedChargingEvents.find(
            (dataPoint) => dataPoint.primary >= datum.primaryValue,
          );

          if (dataPoint) {
            // primary = roundTo(dataPoint.primary, 0); // Use rounding to try avoid charging icon from jumping when adjusted from backend
            primary = dataPoint.primary; // Use rounding to try avoid charging icon from jumping when adjusted from backend
            secondary = dataPoint.secondary;
          }
        }

        if (primary > -1 && secondary > -1) {
          validateChargingEventsHandler.reset();
          resetCandidateChartFormValues();
          setChartHasBeenClicked(true);
          setLoadCandidateChartFormValues(false);
          setCurrentDatumIsAdjustedByCurrentChargingEvent(false);
          setCurrentDatum({ ...datum, secondaryValue: secondary, primaryValue: primary });
          setCurrentDatumTimeAtEvent(
            getCandidateChargingTime({
              dataPoint: {
                primary,
                secondary,
              },
              distanceType: 'excluding-preview',
            }),
          );
        }
      }
    },
    [
      distanceFromSavedChargingEvents,
      validateChargingEventsHandler,
      setCurrentDatumIsAdjustedByCurrentChargingEvent,
      findSavedChargingEvent,
      getCandidateChargingTime,
      setChartHasBeenClicked,
      setLoadCandidateChartFormValues,
      setCurrentDatum,
      setCurrentDatumTimeAtEvent,
      resetCandidateChartFormValues,
    ],
  );

  const options = useMemo(
    () => ({
      tooltip,
      onClickDatum,
    }),
    [tooltip, onClickDatum],
  );

  return (
    <div
      className={classNames('px-spacing-16', {
        [classes['has-current-datum']]: Boolean(currentDatum),
        [classes['no-current-datum']]: !Boolean(currentDatum),
      })}
    >
      <CandidateChargingGraph
        className={classes['graph']}
        data={data}
        genericDailyOdometerKm={genericDailyOdometerKm}
        primaryAxis={primaryAxis}
        secondaryAxes={secondaryAxes}
        vehicleColor={vehicle.enhanced.vehicleColor}
        isPresentation={false}
        active={Boolean(currentDatum)}
        options={options}
      />
    </div>
  );
};

export default CandidateGraph;
