import { Slider } from '@optimization/ssi-common';

interface Props {
  onChangeDailyDistance: (value: string) => void;
  onChangeGtw: (value: string) => void;
  onChangeAvgSpeed: (value: string) => void;
  onChangeCruisingSpeed: (value: string) => void;
  genericDailyOdometerKm: string | number;
  genericMaxGtwKg: string | number;
  avgSpeed: string | number;
  cruisingSpeed: string | number;
  cruisingSpeedMin: string;
  disabled: boolean;
}

const OperationalFactors = ({
  onChangeDailyDistance,
  onChangeGtw,
  onChangeAvgSpeed,
  onChangeCruisingSpeed,
  genericDailyOdometerKm,
  genericMaxGtwKg,
  avgSpeed,
  cruisingSpeed,
  cruisingSpeedMin,
  disabled,
}: Props) => (
  <>
    <Slider
      className="mt-negative-spacing-30"
      onChange={onChangeDailyDistance}
      label="Daily distance (km)"
      min="1"
      max="2000"
      disabled={disabled}
      value={genericDailyOdometerKm}
      dataTestid="slider-daily-distance"
    />
    <Slider
      className="mt-negative-spacing-38"
      onChange={onChangeGtw}
      label="Average GTW (kg)"
      min="10000"
      max="64000"
      disabled={disabled}
      value={genericMaxGtwKg}
    />
    <Slider
      className="mt-negative-spacing-36"
      onChange={onChangeAvgSpeed}
      label="Average speed (km/h)"
      min="0"
      max="90"
      disabled={disabled}
      value={avgSpeed}
      dataTestid="slider-avg-speed"
    />
    <Slider
      className="mt-negative-spacing-40 h-80"
      onChange={onChangeCruisingSpeed}
      label="Cruising speed (km/h)"
      min={cruisingSpeedMin}
      max="100"
      disabled={disabled}
      value={cruisingSpeed}
      dataTestid="slider-cruising-speed"
    />
  </>
);

export default OperationalFactors;
