import classes from './style.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  children: Array<ReactNode> | ReactNode;
  dataTestid?: string;
  className?: string;
}

const Grid = ({ children, dataTestid, className }: Props) => (
  <div data-testid={dataTestid} className={classNames(classes.grid, className)}>
    {children}
  </div>
);

export default Grid;
