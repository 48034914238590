import { SetVehiclesByPerformanceStepByNumberOfYears } from './useVehiclesByPerformanceStep';
import { useCallback } from 'react';

interface Props {
  setVehiclesByPerformanceStepByNumberOfYears: SetVehiclesByPerformanceStepByNumberOfYears;
}

export const useOnChangeNumberOfYears = ({ setVehiclesByPerformanceStepByNumberOfYears }: Props) =>
  useCallback(
    (value: string) => {
      setVehiclesByPerformanceStepByNumberOfYears(parseInt(value, 10));
    },
    [setVehiclesByPerformanceStepByNumberOfYears],
  );

export default useOnChangeNumberOfYears;
