import classes from '../style.module.scss';
import classNames from 'classnames';
import { ElectrificationPlanItem } from '..';
import { OnChangeSlider } from '../utils/useOnChangeSlider';
import { Slider } from '@optimization/ssi-common';
import { useCallback } from 'react';

interface Props {
  electrificationPlanItem: ElectrificationPlanItem;
  electrificationPlanVehicleCount: number;
  sliderValue?: string;
  onChangeSlider: OnChangeSlider;
}

const SliderWrapper = ({
  electrificationPlanItem,
  electrificationPlanVehicleCount,
  sliderValue,
  onChangeSlider,
}: Props) => {
  const onChange = useCallback(
    (value: string) => {
      onChangeSlider({
        numberOfVehicles: Number(value),
        currentPerformanceStep: electrificationPlanItem.performanceStep,
      });
    },
    [electrificationPlanItem, onChangeSlider],
  );

  return (
    <div className={classes.slider} key={electrificationPlanItem.performanceStep}>
      <div className={classNames('tds-detail-05', classes.label)}>{electrificationPlanItem.performanceStep}</div>
      <Slider
        className="mb-negative-spacing-46"
        key={electrificationPlanItem.performanceStep}
        label=""
        min="0"
        max={electrificationPlanVehicleCount.toString()}
        value={sliderValue || '0'}
        sendValueWhileMoving
        onChange={onChange}
      />
    </div>
  );
};

export default SliderWrapper;
