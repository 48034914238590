import { FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

const useTruckExceedingGtw = ({
  selectedBasicTruck,
  highestGtwFromSelectedCandidates,
}: {
  selectedBasicTruck: FactBasicTruck | null;
  highestGtwFromSelectedCandidates: number;
}) => {
  const truckExceedingGtw = useMemo(() => {
    if (!selectedBasicTruck) {
      return 0;
    }

    const selectedBasicTruckMaxGtw = selectedBasicTruck.TechnicalMaxGtwKg;

    return selectedBasicTruckMaxGtw && highestGtwFromSelectedCandidates > selectedBasicTruckMaxGtw
      ? selectedBasicTruckMaxGtw
      : 0;
  }, [highestGtwFromSelectedCandidates, selectedBasicTruck]);

  return truckExceedingGtw;
};

export default useTruckExceedingGtw;
