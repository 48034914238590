interface ApplicationDefaultValues {
  APPLICATION: string;
  AVG_GTW: number;
  AVG_SPEED: number;
  AVG_CRUISE_SPEED: number;
  AVG_DAILY_ODOMETER_95: number;
  AVG_DAILY_ODOMETER_MEDIAN: number;
  AVG_GTW_90: number;
  COUNT: number;
  MOST_COMMON_TOPOGRAPHY: string;
  MOST_COMMON_TRAFFIC: string;
}

const applicationDefaultValues: ApplicationDefaultValues[] = [
  {
    APPLICATION: 'General cargo transport',
    AVG_GTW: 30561,
    AVG_SPEED: 65,
    AVG_CRUISE_SPEED: 89,
    AVG_DAILY_ODOMETER_95: 777,
    AVG_DAILY_ODOMETER_MEDIAN: 431,
    AVG_GTW_90: 43827,
    COUNT: 224191,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'General cargo, haulage',
    AVG_GTW: 28564,
    AVG_SPEED: 64,
    AVG_CRUISE_SPEED: 89,
    AVG_DAILY_ODOMETER_95: 688,
    AVG_DAILY_ODOMETER_MEDIAN: 351,
    AVG_GTW_90: 42248,
    COUNT: 74538,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Tipper',
    AVG_GTW: 31824,
    AVG_SPEED: 45,
    AVG_CRUISE_SPEED: 79,
    AVG_DAILY_ODOMETER_95: 468,
    AVG_DAILY_ODOMETER_MEDIAN: 250,
    AVG_GTW_90: 47476,
    COUNT: 45539,
    MOST_COMMON_TOPOGRAPHY: 'Very hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Temperature controlled transp',
    AVG_GTW: 28283,
    AVG_SPEED: 68,
    AVG_CRUISE_SPEED: 90,
    AVG_DAILY_ODOMETER_95: 774,
    AVG_DAILY_ODOMETER_MEDIAN: 436,
    AVG_GTW_90: 39223,
    COUNT: 26712,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Box, distribution',
    AVG_GTW: 24039,
    AVG_SPEED: 60,
    AVG_CRUISE_SPEED: 88,
    AVG_DAILY_ODOMETER_95: 556,
    AVG_DAILY_ODOMETER_MEDIAN: 296,
    AVG_GTW_90: 35028,
    COUNT: 17909,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Bulk transport',
    AVG_GTW: 32055,
    AVG_SPEED: 64,
    AVG_CRUISE_SPEED: 89,
    AVG_DAILY_ODOMETER_95: 661,
    AVG_DAILY_ODOMETER_MEDIAN: 421,
    AVG_GTW_90: 47417,
    COUNT: 10864,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Grain transport',
    AVG_GTW: 44388,
    AVG_SPEED: 61,
    AVG_CRUISE_SPEED: 89,
    AVG_DAILY_ODOMETER_95: 746,
    AVG_DAILY_ODOMETER_MEDIAN: 376,
    AVG_GTW_90: 62077,
    COUNT: 10424,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Hook lift',
    AVG_GTW: 27367,
    AVG_SPEED: 51,
    AVG_CRUISE_SPEED: 86,
    AVG_DAILY_ODOMETER_95: 430,
    AVG_DAILY_ODOMETER_MEDIAN: 233,
    AVG_GTW_90: 41242,
    COUNT: 8637,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Volume cargo transport',
    AVG_GTW: 28978,
    AVG_SPEED: 68,
    AVG_CRUISE_SPEED: 89,
    AVG_DAILY_ODOMETER_95: 760,
    AVG_DAILY_ODOMETER_MEDIAN: 433,
    AVG_GTW_90: 40986,
    COUNT: 8222,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Fuel transport',
    AVG_GTW: 32189,
    AVG_SPEED: 61,
    AVG_CRUISE_SPEED: 85,
    AVG_DAILY_ODOMETER_95: 683,
    AVG_DAILY_ODOMETER_MEDIAN: 378,
    AVG_GTW_90: 49155,
    COUNT: 7353,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Flatbed with crane',
    AVG_GTW: 25117,
    AVG_SPEED: 54,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 381,
    AVG_DAILY_ODOMETER_MEDIAN: 190,
    AVG_GTW_90: 34801,
    COUNT: 5254,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Bulk ADR transport',
    AVG_GTW: 31016,
    AVG_SPEED: 63,
    AVG_CRUISE_SPEED: 86,
    AVG_DAILY_ODOMETER_95: 653,
    AVG_DAILY_ODOMETER_MEDIAN: 383,
    AVG_GTW_90: 47033,
    COUNT: 5172,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Volume cargo, haulage',
    AVG_GTW: 33807,
    AVG_SPEED: 66,
    AVG_CRUISE_SPEED: 89,
    AVG_DAILY_ODOMETER_95: 847,
    AVG_DAILY_ODOMETER_MEDIAN: 374,
    AVG_GTW_90: 47564,
    COUNT: 4942,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Timber',
    AVG_GTW: 40256,
    AVG_SPEED: 50,
    AVG_CRUISE_SPEED: 83,
    AVG_DAILY_ODOMETER_95: 651,
    AVG_DAILY_ODOMETER_MEDIAN: 333,
    AVG_GTW_90: 62650,
    COUNT: 4663,
    MOST_COMMON_TOPOGRAPHY: 'Very hilly',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Timber transport',
    AVG_GTW: 39432,
    AVG_SPEED: 56,
    AVG_CRUISE_SPEED: 86,
    AVG_DAILY_ODOMETER_95: 690,
    AVG_DAILY_ODOMETER_MEDIAN: 400,
    AVG_GTW_90: 60309,
    COUNT: 4524,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Vehicle transport',
    AVG_GTW: 31062,
    AVG_SPEED: 71,
    AVG_CRUISE_SPEED: 90,
    AVG_DAILY_ODOMETER_95: 791,
    AVG_DAILY_ODOMETER_MEDIAN: 485,
    AVG_GTW_90: 39500,
    COUNT: 4485,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Refuse collection',
    AVG_GTW: 19441,
    AVG_SPEED: 34,
    AVG_CRUISE_SPEED: 79,
    AVG_DAILY_ODOMETER_95: 217,
    AVG_DAILY_ODOMETER_MEDIAN: 130,
    AVG_GTW_90: 27018,
    COUNT: 3982,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Start/Stop',
  },
  {
    APPLICATION: 'Swap body transport',
    AVG_GTW: 25327,
    AVG_SPEED: 67,
    AVG_CRUISE_SPEED: 88,
    AVG_DAILY_ODOMETER_95: 699,
    AVG_DAILY_ODOMETER_MEDIAN: 425,
    AVG_GTW_90: 34055,
    COUNT: 3034,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Heavy haulage transport',
    AVG_GTW: 41112,
    AVG_SPEED: 56,
    AVG_CRUISE_SPEED: 83,
    AVG_DAILY_ODOMETER_95: 585,
    AVG_DAILY_ODOMETER_MEDIAN: 295,
    AVG_GTW_90: 60885,
    COUNT: 2529,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Concrete mixer',
    AVG_GTW: 24619,
    AVG_SPEED: 43,
    AVG_CRUISE_SPEED: 83,
    AVG_DAILY_ODOMETER_95: 257,
    AVG_DAILY_ODOMETER_MEDIAN: 120,
    AVG_GTW_90: 38129,
    COUNT: 2411,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Livestock transport',
    AVG_GTW: 29710,
    AVG_SPEED: 66,
    AVG_CRUISE_SPEED: 91,
    AVG_DAILY_ODOMETER_95: 728,
    AVG_DAILY_ODOMETER_MEDIAN: 379,
    AVG_GTW_90: 42575,
    COUNT: 2161,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Platform, distribution',
    AVG_GTW: 23874,
    AVG_SPEED: 54,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 440,
    AVG_DAILY_ODOMETER_MEDIAN: 232,
    AVG_GTW_90: 34455,
    COUNT: 2037,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Shipping container transport',
    AVG_GTW: 29456,
    AVG_SPEED: 64,
    AVG_CRUISE_SPEED: 89,
    AVG_DAILY_ODOMETER_95: 692,
    AVG_DAILY_ODOMETER_MEDIAN: 412,
    AVG_GTW_90: 45316,
    COUNT: 1938,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Vacuum/Sewer cleaning',
    AVG_GTW: 25067,
    AVG_SPEED: 50,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 289,
    AVG_DAILY_ODOMETER_MEDIAN: 123,
    AVG_GTW_90: 32717,
    COUNT: 1538,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Concrete pump',
    AVG_GTW: 45293,
    AVG_SPEED: 39,
    AVG_CRUISE_SPEED: 74,
    AVG_DAILY_ODOMETER_95: 130,
    AVG_DAILY_ODOMETER_MEDIAN: 46,
    AVG_GTW_90: 51740,
    COUNT: 1501,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Fuel bulk, haulage',
    AVG_GTW: 29809,
    AVG_SPEED: 61,
    AVG_CRUISE_SPEED: 83,
    AVG_DAILY_ODOMETER_95: 695,
    AVG_DAILY_ODOMETER_MEDIAN: 371,
    AVG_GTW_90: 47054,
    COUNT: 1477,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Skip loader',
    AVG_GTW: 18646,
    AVG_SPEED: 44,
    AVG_CRUISE_SPEED: 84,
    AVG_DAILY_ODOMETER_95: 302,
    AVG_DAILY_ODOMETER_MEDIAN: 182,
    AVG_GTW_90: 26196,
    COUNT: 1343,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Fire engine',
    AVG_GTW: 17803,
    AVG_SPEED: 44,
    AVG_CRUISE_SPEED: 91,
    AVG_DAILY_ODOMETER_95: 141,
    AVG_DAILY_ODOMETER_MEDIAN: 28,
    AVG_GTW_90: 23104,
    COUNT: 1335,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Wood chip transport',
    AVG_GTW: 36029,
    AVG_SPEED: 64,
    AVG_CRUISE_SPEED: 88,
    AVG_DAILY_ODOMETER_95: 738,
    AVG_DAILY_ODOMETER_MEDIAN: 474,
    AVG_GTW_90: 52843,
    COUNT: 1307,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Other bulk, haulage',
    AVG_GTW: 30111,
    AVG_SPEED: 58,
    AVG_CRUISE_SPEED: 88,
    AVG_DAILY_ODOMETER_95: 547,
    AVG_DAILY_ODOMETER_MEDIAN: 294,
    AVG_GTW_90: 45837,
    COUNT: 1298,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Milk collection',
    AVG_GTW: 26475,
    AVG_SPEED: 56,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 609,
    AVG_DAILY_ODOMETER_MEDIAN: 407,
    AVG_GTW_90: 42250,
    COUNT: 1284,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Temp controlled, haulage',
    AVG_GTW: 30828,
    AVG_SPEED: 67,
    AVG_CRUISE_SPEED: 89,
    AVG_DAILY_ODOMETER_95: 680,
    AVG_DAILY_ODOMETER_MEDIAN: 367,
    AVG_GTW_90: 43941,
    COUNT: 1129,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Sugar cane transport',
    AVG_GTW: 51022,
    AVG_SPEED: 38,
    AVG_CRUISE_SPEED: 72,
    AVG_DAILY_ODOMETER_95: 536,
    AVG_DAILY_ODOMETER_MEDIAN: 307,
    AVG_GTW_90: 77253,
    COUNT: 1087,
    MOST_COMMON_TOPOGRAPHY: 'Very hilly',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Vehicle transport, haulage',
    AVG_GTW: 31092,
    AVG_SPEED: 70,
    AVG_CRUISE_SPEED: 90,
    AVG_DAILY_ODOMETER_95: 782,
    AVG_DAILY_ODOMETER_MEDIAN: 461,
    AVG_GTW_90: 40195,
    COUNT: 1079,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Temp controlled, distribution',
    AVG_GTW: 21014,
    AVG_SPEED: 57,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 453,
    AVG_DAILY_ODOMETER_MEDIAN: 242,
    AVG_GTW_90: 29173,
    COUNT: 834,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Road sweeping',
    AVG_GTW: 20855,
    AVG_SPEED: 40,
    AVG_CRUISE_SPEED: 84,
    AVG_DAILY_ODOMETER_95: 321,
    AVG_DAILY_ODOMETER_MEDIAN: 144,
    AVG_GTW_90: 29495,
    COUNT: 687,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Tanker, distribution',
    AVG_GTW: 25414,
    AVG_SPEED: 54,
    AVG_CRUISE_SPEED: 86,
    AVG_DAILY_ODOMETER_95: 454,
    AVG_DAILY_ODOMETER_MEDIAN: 248,
    AVG_GTW_90: 39501,
    COUNT: 674,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Mobile crane',
    AVG_GTW: 30508,
    AVG_SPEED: 51,
    AVG_CRUISE_SPEED: 86,
    AVG_DAILY_ODOMETER_95: 330,
    AVG_DAILY_ODOMETER_MEDIAN: 141,
    AVG_GTW_90: 38898,
    COUNT: 595,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Fire and rescue vehicle',
    AVG_GTW: 17619,
    AVG_SPEED: 41,
    AVG_CRUISE_SPEED: 92,
    AVG_DAILY_ODOMETER_95: 97,
    AVG_DAILY_ODOMETER_MEDIAN: 19,
    AVG_GTW_90: 22482,
    COUNT: 576,
    MOST_COMMON_TOPOGRAPHY: 'Very hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Platform with crane',
    AVG_GTW: 24868,
    AVG_SPEED: 52,
    AVG_CRUISE_SPEED: 86,
    AVG_DAILY_ODOMETER_95: 359,
    AVG_DAILY_ODOMETER_MEDIAN: 167,
    AVG_GTW_90: 34270,
    COUNT: 569,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Curtainsider, distribution',
    AVG_GTW: 23789,
    AVG_SPEED: 61,
    AVG_CRUISE_SPEED: 90,
    AVG_DAILY_ODOMETER_95: 583,
    AVG_DAILY_ODOMETER_MEDIAN: 324,
    AVG_GTW_90: 34416,
    COUNT: 427,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Chemical bulk, haulage',
    AVG_GTW: 30906,
    AVG_SPEED: 62,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 606,
    AVG_DAILY_ODOMETER_MEDIAN: 350,
    AVG_GTW_90: 47586,
    COUNT: 409,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Fluid Traffic',
  },
  {
    APPLICATION: 'Road sweeper, vacuum tanker',
    AVG_GTW: 23482,
    AVG_SPEED: 43,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 288,
    AVG_DAILY_ODOMETER_MEDIAN: 105,
    AVG_GTW_90: 30936,
    COUNT: 262,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Recovery',
    AVG_GTW: 23924,
    AVG_SPEED: 56,
    AVG_CRUISE_SPEED: 88,
    AVG_DAILY_ODOMETER_95: 397,
    AVG_DAILY_ODOMETER_MEDIAN: 139,
    AVG_GTW_90: 32303,
    COUNT: 221,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Recovery vehicle, mobile crane',
    AVG_GTW: 30583,
    AVG_SPEED: 54,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 380,
    AVG_DAILY_ODOMETER_MEDIAN: 137,
    AVG_GTW_90: 40660,
    COUNT: 172,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Aerial platform',
    AVG_GTW: 23024,
    AVG_SPEED: 49,
    AVG_CRUISE_SPEED: 86,
    AVG_DAILY_ODOMETER_95: 307,
    AVG_DAILY_ODOMETER_MEDIAN: 138,
    AVG_GTW_90: 31184,
    COUNT: 152,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Water/foam carrier',
    AVG_GTW: 23312,
    AVG_SPEED: 47,
    AVG_CRUISE_SPEED: 85,
    AVG_DAILY_ODOMETER_95: 334,
    AVG_DAILY_ODOMETER_MEDIAN: 180,
    AVG_GTW_90: 33928,
    COUNT: 67,
    MOST_COMMON_TOPOGRAPHY: 'Hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Recovery truck',
    AVG_GTW: 20666,
    AVG_SPEED: 49,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 270,
    AVG_DAILY_ODOMETER_MEDIAN: 130,
    AVG_GTW_90: 27497,
    COUNT: 47,
    MOST_COMMON_TOPOGRAPHY: 'Very hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Aircraft refuelling',
    AVG_GTW: 40181,
    AVG_SPEED: 29,
    AVG_CRUISE_SPEED: 53,
    AVG_DAILY_ODOMETER_95: 135,
    AVG_DAILY_ODOMETER_MEDIAN: 76,
    AVG_GTW_90: 53532,
    COUNT: 40,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Airport support vehicle',
    AVG_GTW: 25770,
    AVG_SPEED: 29,
    AVG_CRUISE_SPEED: 63,
    AVG_DAILY_ODOMETER_95: 51,
    AVG_DAILY_ODOMETER_MEDIAN: 12,
    AVG_GTW_90: 33610,
    COUNT: 31,
    MOST_COMMON_TOPOGRAPHY: 'Very hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Aerial platform truck',
    AVG_GTW: 26522,
    AVG_SPEED: 50,
    AVG_CRUISE_SPEED: 85,
    AVG_DAILY_ODOMETER_95: 239,
    AVG_DAILY_ODOMETER_MEDIAN: 71,
    AVG_GTW_90: 31838,
    COUNT: 21,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Airpt sweeping',
    AVG_GTW: 28465,
    AVG_SPEED: 25,
    AVG_CRUISE_SPEED: 50,
    AVG_DAILY_ODOMETER_95: 177,
    AVG_DAILY_ODOMETER_MEDIAN: 33,
    AVG_GTW_90: 36194,
    COUNT: 14,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Light Traffic',
  },
  {
    APPLICATION: 'Airport crash tender',
    AVG_GTW: 17801,
    AVG_SPEED: 41,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 49,
    AVG_DAILY_ODOMETER_MEDIAN: 10,
    AVG_GTW_90: 22480,
    COUNT: 14,
    MOST_COMMON_TOPOGRAPHY: 'Very hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Turntable ladder',
    AVG_GTW: 14880,
    AVG_SPEED: 39,
    AVG_CRUISE_SPEED: 90,
    AVG_DAILY_ODOMETER_95: 43,
    AVG_DAILY_ODOMETER_MEDIAN: 6,
    AVG_GTW_90: 19409,
    COUNT: 13,
    MOST_COMMON_TOPOGRAPHY: 'Very hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Airport sweeping',
    AVG_GTW: 24909,
    AVG_SPEED: 41,
    AVG_CRUISE_SPEED: 82,
    AVG_DAILY_ODOMETER_95: 386,
    AVG_DAILY_ODOMETER_MEDIAN: 120,
    AVG_GTW_90: 40074,
    COUNT: 3,
    MOST_COMMON_TOPOGRAPHY: 'Very hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Aircraft catering',
    AVG_GTW: 20698,
    AVG_SPEED: 35,
    AVG_CRUISE_SPEED: 87,
    AVG_DAILY_ODOMETER_95: 63,
    AVG_DAILY_ODOMETER_MEDIAN: 32,
    AVG_GTW_90: 28216,
    COUNT: 2,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Turntable ladder truck',
    AVG_GTW: 15017,
    AVG_SPEED: 44,
    AVG_CRUISE_SPEED: 102,
    AVG_DAILY_ODOMETER_95: 35,
    AVG_DAILY_ODOMETER_MEDIAN: 2,
    AVG_GTW_90: 19017,
    COUNT: 2,
    MOST_COMMON_TOPOGRAPHY: 'Very hilly',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
  {
    APPLICATION: 'Airport deicing',
    AVG_GTW: 30506,
    AVG_SPEED: 30,
    AVG_CRUISE_SPEED: 81,
    AVG_DAILY_ODOMETER_95: 138,
    AVG_DAILY_ODOMETER_MEDIAN: 9,
    AVG_GTW_90: 38544,
    COUNT: 1,
    MOST_COMMON_TOPOGRAPHY: 'Flat',
    MOST_COMMON_TRAFFIC: 'Residential Traffic',
  },
];

export default applicationDefaultValues;
