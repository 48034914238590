import { useCallback } from 'react';

interface Props {
  genericMaxGtwKgMin: number;
  genericMaxGtwKgMax: number;
}

const useEnsureInGtwRange = ({ genericMaxGtwKgMin, genericMaxGtwKgMax }: Props) =>
  useCallback(
    (value: number) => {
      let genericMaxGtwKg = value;

      if (genericMaxGtwKg < genericMaxGtwKgMin) {
        genericMaxGtwKg = genericMaxGtwKgMin;
      } else if (genericMaxGtwKg > genericMaxGtwKgMax) {
        genericMaxGtwKg = genericMaxGtwKgMax;
      }

      return genericMaxGtwKg;
    },
    [genericMaxGtwKgMin, genericMaxGtwKgMax],
  );

export default useEnsureInGtwRange;
