import { TElectrificationPlan } from '..';
import { useMemo } from 'react';

interface Props {
  electrificationPlan: TElectrificationPlan;
}

export const useSelectedYearsCount = ({ electrificationPlan }: Props): number =>
  useMemo(() => electrificationPlan.length, [electrificationPlan]);

export default useSelectedYearsCount;
