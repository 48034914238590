import applicationDefaultValues from './utils/applicationDefaultValues';
import classes from './style.module.scss';
import classNames from 'classnames';
import OperationalFactors from 'features/candidate/utils/OperationalFactors';
import useAddVehicleHandler from './utils/useAddVehicleHandler';
import useEnsureInGtwRange from './utils/useEnsureInGtwRange';
import UserFactors from './UserFactors';
import useSelectedBasicBusEarliest from 'features/candidate/utils/vehicleOptionsHandler/utils/useSelectedBasicBusEarliest';
import useSelectedBasicTruckEarliest from 'features/candidate/utils/vehicleOptionsHandler/utils/useSelectedBasicTruckEarliest';
import useSetApplicationDefaultValues from './utils/useSetApplicationDefaultValues';
import useTabs from './utils/useTabs';
import useVehicleOptionsHandler from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';
import VehicleConfiguration from './VehicleConfiguration';
import { Accordion, Button, Dropdown, InlineTabs, Spinner, TextField } from '@optimization/ssi-common';
import { GTW_MAX_DEFAULT, GTW_MIN_DEFAULT } from 'app/config';
import { IOperationalFactors, IUserFactors } from './types';
import { NO_VALUE_SIGN } from '@optimization/sa-common';
import { TdsDivider } from '@scania/tegel-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnsureArray } from '@optimization/ssi-common';
import { useGetFactBasicDataQuery } from 'app/services/solution';

interface Props {
  solutionId: string;
  drawerWidth: number;
  deactivateAddVehicleDrawer: () => void;
}

const DefineManually = ({ solutionId, drawerWidth, deactivateAddVehicleDrawer }: Props) => {
  const factBasicDataQuery = useGetFactBasicDataQuery();

  const [candidateName, setCandidateName] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const [vehicleConfigurationIsExpanded, setVehicleConfigurationIsExpanded] = useState(true);
  const [operationalFactorsIsExpanded, setOperationalFactorsIsExpanded] = useState(true);
  const [userFactorsIsExpanded, setUserFactorsIsExpanded] = useState(true);

  const tabs = useTabs();

  const [userFactors, setUserFactors] = useState<IUserFactors>({
    climate: '',
    topography: '',
    traffic: '',
  });

  const [operationalFactors, setOperationalFactors] = useState<IOperationalFactors>({
    genericMaxGtwKg: GTW_MIN_DEFAULT,
    avgSpeed: 0,
    cruisingSpeed: 0,
    genericDailyOdometerKm: 1,
  });

  const [defaultOfSelected, setDefaultOfSelected] = useState('');

  const vehicleOptionsHandler = useVehicleOptionsHandler({});

  const { chassisType } = vehicleOptionsHandler.selections;

  const basicTrucks = useEnsureArray(factBasicDataQuery.data?.FactBasicTrucks);
  const basicBusses = useEnsureArray(factBasicDataQuery.data?.FactBasicBusses);

  const selectedBasicTruck = useSelectedBasicTruckEarliest({
    basicTrucks,
    selections: vehicleOptionsHandler.selections,
  });

  const selectedBasicBus = useSelectedBasicBusEarliest({
    basicBusses,
    selections: vehicleOptionsHandler.selections,
  });

  const selectedBasicVehicle = useMemo(
    () => (chassisType === 'Truck' ? selectedBasicTruck : selectedBasicBus),
    [selectedBasicTruck, selectedBasicBus, chassisType],
  );

  const hasSelectedCab = useMemo(
    () => Boolean(vehicleOptionsHandler.selections.truckCabType),
    [vehicleOptionsHandler.selections.truckCabType],
  );

  const hasSelectedFloorHeight = useMemo(
    () => Boolean(vehicleOptionsHandler.selections.busFloorHeight),
    [vehicleOptionsHandler.selections.busFloorHeight],
  );

  const hasAllOperationalFactorsValues = useMemo(
    () => Object.values(operationalFactors).every(Boolean),
    [operationalFactors],
  );

  const hasAllUserFactorsValues = useMemo(() => Object.values(userFactors).every(Boolean), [userFactors]);

  const allInputsFilled = useMemo(
    () =>
      ((hasSelectedCab && chassisType === 'Truck') || (hasSelectedFloorHeight && chassisType === 'Bus')) &&
      hasAllUserFactorsValues &&
      hasAllOperationalFactorsValues,
    [chassisType, hasSelectedCab, hasSelectedFloorHeight, hasAllUserFactorsValues, hasAllOperationalFactorsValues],
  );

  const addVehicleHandler = useAddVehicleHandler({
    candidateName,
    solutionId,
    selectedBasicTruck,
    selectedBasicBus,
    selections: vehicleOptionsHandler.selections,
    userFactors,
    operationalFactors,
    deactivateAddVehicleDrawer,
  });

  const disabled = addVehicleHandler.isLoading;

  const genericMaxGtwKgMin = useMemo(
    () => selectedBasicTruck?.TechnicalMinGtwKg || GTW_MIN_DEFAULT,
    [selectedBasicTruck],
  );
  const genericMaxGtwKgMax = useMemo(
    () => selectedBasicTruck?.TechnicalMaxGtwKg || GTW_MAX_DEFAULT,
    [selectedBasicTruck],
  );

  const ensureInGtwRange = useEnsureInGtwRange({ genericMaxGtwKgMin, genericMaxGtwKgMax });

  const setApplicationDefaultValues = useSetApplicationDefaultValues({
    ensureInGtwRange,
    setDefaultOfSelected,
    setOperationalFactors,
  });

  const goToVehicleConfigurationTab = useCallback(() => {
    setTabIndex(0);
  }, []);

  const goToOperationTab = useCallback(() => {
    setTabIndex(1);
  }, []);

  useEffect(
    () =>
      setOperationalFactors((prev) => ({
        ...prev,
        genericMaxGtwKg: ensureInGtwRange(prev.genericMaxGtwKg),
      })),
    [ensureInGtwRange],
  );

  const toggleVehicleConfiguration = useCallback(() => {
    setVehicleConfigurationIsExpanded((prev) => !prev);
  }, []);

  const toggleOperationalFactors = useCallback(() => {
    setOperationalFactorsIsExpanded((prev) => !prev);
  }, []);

  const toggleUserFactors = useCallback(() => {
    setUserFactorsIsExpanded((prev) => !prev);
  }, []);

  const onChangeDailyDistance = useCallback(
    (value: string) =>
      setOperationalFactors((prev) => ({
        ...prev,
        genericDailyOdometerKm: Number(value),
      })),
    [setOperationalFactors],
  );

  const onChangeGtw = useCallback(
    (value: string) =>
      setOperationalFactors((prev) => ({
        ...prev,
        genericMaxGtwKg: Number(value),
      })),
    [setOperationalFactors],
  );

  const onChangeAvgSpeed = useCallback(
    (value: string) =>
      setOperationalFactors((prev) => ({
        ...prev,
        avgSpeed: Number(value),
      })),
    [setOperationalFactors],
  );

  const onChangeCruisingSpeed = useCallback(
    (value: string) =>
      setOperationalFactors((prev) => ({
        ...prev,
        cruisingSpeed: Number(value),
      })),
    [setOperationalFactors],
  );

  const applicationOptions = useMemo(
    () =>
      applicationDefaultValues.map((appValue) => ({
        name: appValue.APPLICATION,
        value: appValue.APPLICATION,
      })),
    [],
  );

  const vehicleStatus = useMemo(
    () => (selectedBasicVehicle ? selectedBasicVehicle.Status : NO_VALUE_SIGN),
    [selectedBasicVehicle],
  );

  const vehiclePeriod = useMemo(
    () => (selectedBasicVehicle ? selectedBasicVehicle.Period : NO_VALUE_SIGN),
    [selectedBasicVehicle],
  );

  return (
    <div className={classNames(classes['define-manually'])}>
      {!addVehicleHandler.isLoading && (
        <>
          <InlineTabs tabs={tabs} tabIndex={tabIndex} setTabIndex={setTabIndex} />
          {tabs[tabIndex].name === 'Vehicle configuration' && (
            <div className="mx-spacing-16 mt-spacing-24">
              <TextField
                label="Name"
                labelPosition="outside"
                placeholder="Vehicle name is optional"
                variant="secondary"
                dataTestid="textfield-candidate-name"
                value={candidateName}
                onChange={setCandidateName}
              />
              <Accordion
                className="mt-spacing-24"
                hideLastBorder
                items={[
                  {
                    header: 'Vehicle configuration',
                    expanded: vehicleConfigurationIsExpanded,
                    onToggle: toggleVehicleConfiguration,
                    children: (
                      <VehicleConfiguration
                        className="mt-spacing-8"
                        dataTestidBase="dropdown-define-manually"
                        disabled={disabled}
                        vehicleOptionsHandler={vehicleOptionsHandler}
                      />
                    ),
                  },
                ]}
              />
            </div>
          )}
          {tabs[tabIndex].name === 'Operation' && (
            <Accordion
              className="mx-spacing-16"
              hideLastBorder
              items={[
                {
                  header: 'Operational factors',
                  expanded: operationalFactorsIsExpanded,
                  onToggle: toggleOperationalFactors,
                  children: (
                    <>
                      {chassisType === 'Truck' && (
                        <>
                          <div className={classNames('mt-spacing-8', classes.fields)}>
                            <div>Application</div>
                            <Dropdown
                              value=""
                              placeholder="Select an option..."
                              dataTestid="dropdown-define-manually-application"
                              disabled={disabled}
                              options={applicationOptions}
                              onChange={setApplicationDefaultValues}
                            />
                          </div>
                          <div className="my-spacing-24">
                            <TdsDivider />
                          </div>
                        </>
                      )}
                      <div key={defaultOfSelected}>
                        <OperationalFactors
                          genericDailyOdometerKm={operationalFactors.genericDailyOdometerKm}
                          genericMaxGtwKg={operationalFactors.genericMaxGtwKg}
                          avgSpeed={operationalFactors.avgSpeed}
                          cruisingSpeed={operationalFactors.cruisingSpeed}
                          cruisingSpeedMin={operationalFactors.avgSpeed.toString()}
                          disabled={disabled}
                          onChangeDailyDistance={onChangeDailyDistance}
                          onChangeGtw={onChangeGtw}
                          onChangeAvgSpeed={onChangeAvgSpeed}
                          onChangeCruisingSpeed={onChangeCruisingSpeed}
                        />
                      </div>
                    </>
                  ),
                },
                {
                  header: 'User factors',
                  expanded: userFactorsIsExpanded,
                  onToggle: toggleUserFactors,
                  children: (
                    <UserFactors className="mt-spacing-8" disabled={disabled} setUserFactors={setUserFactors} />
                  ),
                },
              ]}
            />
          )}
        </>
      )}
      <div className={classes.footer} style={{ width: `${drawerWidth}px` }}>
        <div className={classes.status}>
          <div>
            <div className="tds-detail-06 tds-text-grey-400">Status</div>
            <div className="tds-headline-06 tds-text-white">{vehicleStatus}</div>
          </div>
          <div>
            <div className="tds-detail-06 tds-text-grey-400">Period</div>
            <div className="tds-headline-06 tds-text-white">{vehiclePeriod}</div>
          </div>
        </div>
        {addVehicleHandler.isLoading ? (
          <div className={classes.loading}>
            <p>Adding vehicle candidate to solution...</p>
            <Spinner />
          </div>
        ) : (!hasAllOperationalFactorsValues || !hasAllUserFactorsValues) &&
          tabs[tabIndex].name === 'Vehicle configuration' ? (
          <Button
            text="Go to operation"
            disabled={
              (!hasSelectedCab && chassisType === 'Truck') ||
              (!hasSelectedFloorHeight && chassisType === 'Bus') ||
              disabled
            }
            variant={
              (hasSelectedCab && chassisType === 'Truck') || (hasSelectedFloorHeight && chassisType === 'Bus')
                ? 'primary'
                : 'secondary'
            }
            dataTestid="button-go-to-operation"
            fullbleed
            onClick={goToOperationTab}
          />
        ) : ((!hasSelectedCab && chassisType === 'Truck') || (!hasSelectedFloorHeight && chassisType === 'Bus')) &&
          tabs[tabIndex].name === 'Operation' ? (
          <Button
            text="Go to vehicle configuration"
            disabled={!hasAllOperationalFactorsValues || !hasAllUserFactorsValues || disabled}
            variant={hasAllOperationalFactorsValues && hasAllUserFactorsValues ? 'primary' : 'secondary'}
            fullbleed
            onClick={goToVehicleConfigurationTab}
          />
        ) : (
          <Button
            text="Add vehicle candidate"
            disabled={!allInputsFilled || disabled}
            variant={allInputsFilled ? 'primary' : 'secondary'}
            dataTestid="button-add-manually-defined-candidate"
            fullbleed
            onClick={addVehicleHandler.addVehicle}
          />
        )}
      </div>
    </div>
  );
};

export default DefineManually;
