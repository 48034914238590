import {
  useCallback,
  useEffect,
  useMemo,
  useState
  } from 'react';
import { ValidateChassisNumbersVehicleResponseV2VM } from '@optimization/sa-common';
import { VehiclesByPerformanceStep } from '..';

export type SetVehiclesByPerformanceStepByNumberOfYears = (numberOfYears: number) => void;

interface Props {
  validateChassisData?: ValidateChassisNumbersVehicleResponseV2VM;
}

const useVehiclesByPerformanceStep = ({ validateChassisData }: Props) => {
  const [vehiclesByPerformanceStep, setVehiclesByPerformanceStep] = useState<VehiclesByPerformanceStep>();

  const setVehiclesByPerformanceStepByNumberOfYears: SetVehiclesByPerformanceStepByNumberOfYears = useCallback(
    (numberOfYears: number) => {
      if (
        validateChassisData?.ExecutableVehiclesPerPerformanceStep &&
        Object.keys(validateChassisData.ExecutableVehiclesPerPerformanceStep).length
      ) {
        const result: VehiclesByPerformanceStep = {};

        const minimumPerformanceStep = Object.keys(validateChassisData.ExecutableVehiclesPerPerformanceStep).reduce(
          (minimum, performanceStep) => Math.min(minimum, parseInt(performanceStep)),
          Number.MAX_SAFE_INTEGER,
        );

        const years = Array.from({ length: numberOfYears }, (_, index) => minimumPerformanceStep + index);

        let index = 0;

        Object.keys(validateChassisData.ExecutableVehiclesPerPerformanceStep).forEach((performanceStep) => {
          const vehiclesIds = validateChassisData.ExecutableVehiclesPerPerformanceStep[performanceStep];

          for (const vehicleId of vehiclesIds) {
            const year = years[index % years.length];
            const validYear = Number(performanceStep) <= year ? year : Number(performanceStep);

            if (result[validYear]) {
              result[validYear].push(vehicleId);
            } else {
              result[validYear] = [vehicleId];
            }

            index++;
          }
        });

        setVehiclesByPerformanceStep(result);
      } else {
        setVehiclesByPerformanceStep(undefined);
      }
    },
    [validateChassisData],
  );

  useEffect(() => {
    setVehiclesByPerformanceStepByNumberOfYears(5);
  }, [setVehiclesByPerformanceStepByNumberOfYears]);

  return useMemo(
    () => ({ vehiclesByPerformanceStep, setVehiclesByPerformanceStepByNumberOfYears }),
    [vehiclesByPerformanceStep, setVehiclesByPerformanceStepByNumberOfYears],
  );
};

export default useVehiclesByPerformanceStep;
