import { KeyedString } from '@optimization/ssi-common';
import { useMemo } from 'react';
import { ValidateChassisNumbersVehicleResponseV2VM } from '@optimization/sa-common';

interface Props {
  validateChassisData?: ValidateChassisNumbersVehicleResponseV2VM;
}

const useMinimumPerformanceStepByVehicle = ({ validateChassisData }: Props): KeyedString =>
  useMemo(
    () =>
      validateChassisData
        ? Object.keys(validateChassisData.ExecutableVehiclesPerPerformanceStep).reduce(
            (acc, performanceStep) => ({
              ...acc,
              ...validateChassisData.ExecutableVehiclesPerPerformanceStep[performanceStep].reduce(
                (accInner, vehicleId) => ({ ...accInner, [vehicleId]: performanceStep }),
                {},
              ),
            }),
            {},
          )
        : {},

    [validateChassisData],
  );

export default useMinimumPerformanceStepByVehicle;
