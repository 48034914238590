import { useMemo } from 'react';

interface WarningMessage {
  header: string;
  body: string;
}

interface UseWarningExceedingMessageProps {
  truckExceedingDailyDistance: number;
  truckExceedingGtw: number;
}

const useWarningExceedingMessage = ({
  truckExceedingDailyDistance,
  truckExceedingGtw,
}: UseWarningExceedingMessageProps): WarningMessage => {
  const warningExceedingMessage = useMemo(() => {
    if (truckExceedingDailyDistance && truckExceedingGtw) {
      return {
        header: 'Exceeding recommended daily distance and GTW',
        body: `The daily distance and GTW of one or more vehicle candidates exceeds product recommended limit of ${truckExceedingDailyDistance} km and ${
          truckExceedingGtw / 1000
        } t respectively.`,
      };
    } else if (truckExceedingDailyDistance) {
      return {
        header: 'Exceeding recommended daily distance',
        body: `The daily distance of one or more vehicle candidates exceeds product recommended limit of ${truckExceedingDailyDistance} km`,
      };
    } else if (truckExceedingGtw) {
      return {
        header: 'Exceeding recommended GTW',
        body: `The GTW of one or more vehicle candidates exceeds product recommended limit of ${truckExceedingGtw / 1000} t`,
      };
    }

    return {
      header: '',
      body: '',
    };
  }, [truckExceedingGtw, truckExceedingDailyDistance]);

  return warningExceedingMessage;
};

export default useWarningExceedingMessage;
