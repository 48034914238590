import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  selectedCandidates: string[];
  vehicles?: ListVehicleTransformed[];
}

const useHighestGtwFromSelectedCandidates = ({ selectedCandidates, vehicles }: Props): number =>
  useMemo(() => {
    const selectedVehicles = vehicles?.filter((vehicle) => selectedCandidates.includes(vehicle.Id.toString()));

    if (selectedVehicles && selectedVehicles?.length > 0) {
      const genericMaxGtwKgs = selectedVehicles
        .map((vehicle) => Number(vehicle.GenericMaxGtwKg))
        .filter((value) => value);

      const highestGtw = Math.max(...genericMaxGtwKgs);

      return highestGtw;
    }

    return 0;
  }, [selectedCandidates, vehicles]);

export default useHighestGtwFromSelectedCandidates;
