import ActionLayout from 'common/components/ActionLayout';
import Candidates from 'features/candidate/Candidates';
import CandidatesFilterSelector from 'features/candidate/Candidates/Filter/Selector';
import CandidatesFilterToggle from 'features/candidate/Candidates/Filter/Toggle';
import ConfigureVehicleProduct from './ConfigureVehicleProduct';
import HelmetTitle from 'common/components/HelmetTitle';
import PageNotFound from 'common/components/PageNotFound';
import SetupPto from './SetupPto';
import SolutionPageHeader from 'features/solution/utils/SolutionPageHeader';
import TotalCount from 'common/components/TotalCount';
import useCandidatesHandler from 'features/candidate/Candidates/utils/useCandidatesHandler';
import useConfigureVehicleProductHandler from './utils/useConfigureVehicleProductHandler';
import useDeleteCandidatesHandler from './utils/useDeleteCandidatesHandler';
import useDuplicateCandidatesHandler from './utils/useDuplicateCandidatesHandler';
import useHandleSelectAll from 'common/hooks/useHandleSelectAll';
import useHighestDailyDistanceFromSelectedCandidates from './utils/useHighestDailyDistanceFromSelectedCandidates';
import useHighestGtwFromSelectedCandidates from './utils/useHighestGtwFromSelectedCandidates';
import useLoadingText from 'common/hooks/useLoadingText';
import useSelectCandidateRow from 'features/candidate/utils/useSelectCandidateRow';
import useSetupPtoHandler from './utils/useSetupPtoHandler';
import useSolutionHasVehicles from '../utils/useSolutionHasVehicles';
import { BackLink, useEnhancedListVehicles, useVehicleSettingsHandler } from '@optimization/sa-common';
import { isError404 } from 'common/utils/helper';
import { TdsMessage } from '@scania/tegel-react';
import { useAddVehicle } from 'app/context/AddVehicleContext';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonAlt,
  Checkbox,
  invariant,
  Loading,
  Modal,
  PopoverMenu,
  usePlural,
} from '@optimization/ssi-common';
import {
  useGetVehiclesQuery,
  useGetSolutionQuery,
  useGetPtoDevicesQuery,
  useGetFactBasicDataQuery,
} from 'app/services/solution';

type PanelType = 'configure-vehicle-product' | 'setup-pto';
type ModalType = 'duplicate-candidates' | 'delete-candidates' | 'add-pto';
type ModeType = PanelType | ModalType;

const manageCandidatesOptions = [
  {
    value: 'setup-pto',
    name: 'Setup PTO',
    dataTestid: 'button-setup-pto-menu-item',
  },
  {
    value: 'configure-vehicle-product',
    name: 'Configure vehicle product',
    dataTestid: 'button-configure-vehicle-product-menu-item',
  },
  {
    value: 'duplicate-candidates',
    name: 'Duplicate candidates',
    dataTestid: 'button-duplicate-candidates-action-menu-item',
  },
  {
    value: 'delete-candidates',
    name: 'Delete candidates',
    dataTestid: 'button-delete-candidates-action-menu-item',
  },
];

const SolutionDetail = () => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const factBasicDataQuery = useGetFactBasicDataQuery();
  const getPtoDevicesQuery = useGetPtoDevicesQuery();
  const solutionQuery = useGetSolutionQuery(solutionId);
  const vehiclesQuery = useGetVehiclesQuery(solutionId);
  const solutionHasVehicles = useSolutionHasVehicles({ solutionId });

  const actionLayoutRef = useRef<HTMLDivElement | null>(null);
  const [selectedMode, setSelectedMode] = useState<ModeType | undefined>();
  const [selectedCandidates, setSelectedCandidates] = useState<string[]>([]);
  const [rejected, setRejected] = useState(0);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [modal, setModal] = useState<ModalType | null>(null);
  const [panel, setPanel] = useState<PanelType | null>(null);

  const addVehicle = useAddVehicle();

  const resetMode = useCallback(() => {
    setSelectedMode(undefined);
  }, []);

  const closePanel = useCallback(() => {
    setPanel(null);
  }, []);

  const showConfigureVehicleProductPanel = useCallback(() => {
    setPanel('configure-vehicle-product');
  }, []);

  const showSetupPtoPanel = useCallback(() => {
    setPanel('setup-pto');
  }, []);

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  const closeModalAndResetRejected = useCallback(() => {
    setRejected(0);
    closeModal();
  }, [closeModal]);

  const showDeleteCandidatesModal = useCallback(() => {
    setModal('delete-candidates');
  }, []);

  const showDuplicateCandidatesModal = useCallback(() => {
    setModal('duplicate-candidates');
  }, []);

  const closeAction = useCallback(() => {
    setSelectedMode(undefined);
    setSelectedCandidates([]);
    closePanel();
    closeModal();
  }, [closePanel, closeModal]);

  const highestDailyDistanceFromSelectedCandidates = useHighestDailyDistanceFromSelectedCandidates({
    vehicles: vehiclesQuery.data,
    selectedCandidates,
  });

  const highestGtwFromSelectedCandidates = useHighestGtwFromSelectedCandidates({
    vehicles: vehiclesQuery.data,
    selectedCandidates,
  });

  const enhancedVehicles = useEnhancedListVehicles({
    vehicles: vehiclesQuery.data,
    factBasicDataQueryData: factBasicDataQuery.data,
  });

  const selectedVehicles = useMemo(
    () => enhancedVehicles.filter((vehicle) => selectedCandidates.includes(vehicle.Id.toString())) || [],
    [enhancedVehicles, selectedCandidates],
  );

  const handleSelectRow = useSelectCandidateRow({ setSelectedCandidates });

  const showOnlySelectedCandidates = useMemo(
    () => (panel ? ['setup-pto', 'configure-vehicle-product'].includes(panel) : false),
    [panel],
  );

  const vehicleSettingsHandler = useVehicleSettingsHandler();

  const candidatesHandler = useCandidatesHandler({
    solutionId,
    selectedCandidates,
    showOnlySelectedCandidates,
    includeCandidateWithNonExecutedVehicleProduct: selectedMode !== 'setup-pto',
    vehicleSettingsById: vehicleSettingsHandler.vehicleSettingsById,
    chassisType: selectedMode === 'setup-pto' ? 'Truck' : undefined,
    hasSetChargingEvents: selectedMode === 'configure-vehicle-product' ? false : undefined,
  });

  const configureVehicleProductHandler = useConfigureVehicleProductHandler({
    solutionId,
    closePanel,
    setSelectedCandidates,
    resetMode,
  });

  const deleteCandidatesHandler = useDeleteCandidatesHandler({
    solutionId,
    selectedCandidates,
    closeModal,
    setSelectedCandidates,
    setRejected,
    resetMode,
  });

  const duplicateCandidatesHandler = useDuplicateCandidatesHandler({
    solutionId,
    selectedCandidates,
    setSelectedCandidates,
    setRejected,
    resetMode,
    closeModal,
  });

  const setupPtoHandler = useSetupPtoHandler({
    solutionId,
    selectedVehicles,
    ptoDevices: getPtoDevicesQuery.data,
    onClose: closeAction,
  });

  const handleManageAction = useCallback(async () => {
    switch (selectedMode) {
      case 'configure-vehicle-product':
        showConfigureVehicleProductPanel();
        break;
      case 'duplicate-candidates':
        showDuplicateCandidatesModal();
        break;
      case 'delete-candidates':
        showDeleteCandidatesModal();
        break;
      case 'setup-pto':
        showSetupPtoPanel();
        break;
    }
  }, [
    selectedMode,
    showDuplicateCandidatesModal,
    showDeleteCandidatesModal,
    showConfigureVehicleProductPanel,
    showSetupPtoPanel,
  ]);

  const handleSelectAllShown = useHandleSelectAll({
    items: candidatesHandler.splicedCandidates,
    setSelectedItems: setSelectedCandidates,
  });

  const handleSelectAllExisting = useHandleSelectAll({
    items: candidatesHandler.filteredCandidates,
    setSelectedItems: setSelectedCandidates,
  });

  useMakeElementsSticky({
    actionLayoutRef: candidatesHandler.isSuccess && actionLayoutRef.current ? actionLayoutRef : undefined,
  });

  const manageCandidatesPopoverOptions = useMemo(
    () =>
      manageCandidatesOptions.map((option) => ({
        name: option.name,
        dataTestid: option.dataTestid,
        onClick: () => {
          setSelectedMode(option.value as ModeType);
          setShowActionMenu(false);
        },
      })),
    [],
  );

  const allCandidatesIsSelected = useMemo(
    () => selectedCandidates.length === candidatesHandler.filteredCandidates?.length,
    [selectedCandidates, candidatesHandler],
  );

  const candidatesPluralLowercase = usePlural({
    count: selectedCandidates.length,
    uppercase: false,
  });

  const candidatesPluralUppercase = usePlural({
    count: selectedCandidates.length,
    uppercase: true,
  });

  const rejectedPlural = usePlural({ count: rejected });

  const loadingText = useLoadingText({
    isLoadingSolution: solutionQuery.isFetching,
    isLoadingCandidates: vehiclesQuery.isFetching || candidatesHandler.isLoading,
    isConfiguringVehicleProducts: configureVehicleProductHandler.isLoading,
    isLoadingPtoDevices: getPtoDevicesQuery.isLoading,
    isSavingPto: setupPtoHandler.isLoading,
    isDeletingCandidates: deleteCandidatesHandler.isLoading,
    isDuplicatingCandidates: duplicateCandidatesHandler.isLoading,
  });

  const isLoading =
    solutionQuery.isFetching ||
    vehiclesQuery.isFetching ||
    duplicateCandidatesHandler.isLoading ||
    configureVehicleProductHandler.isLoading ||
    setupPtoHandler.isLoading ||
    candidatesHandler.isLoading ||
    getPtoDevicesQuery.isLoading ||
    deleteCandidatesHandler.isLoading;

  const isError =
    solutionQuery.isError ||
    vehiclesQuery.isError ||
    configureVehicleProductHandler.isError ||
    setupPtoHandler.isError ||
    candidatesHandler.isError ||
    getPtoDevicesQuery.isError;

  const isSuccess =
    solutionQuery.isSuccess && vehiclesQuery.isSuccess && candidatesHandler.isSuccess && getPtoDevicesQuery.isSuccess;

  if (isError404(solutionQuery.error)) {
    return <PageNotFound />;
  }

  return (
    <div className="tds-container">
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <HelmetTitle solutionId={solutionId} items={['Vehicle candidates']} />
      {modal === 'delete-candidates' && (
        <Modal
          size="xs"
          disabled={isLoading}
          header={`Delete vehicle candidate${candidatesPluralLowercase}`}
          ctaConfirmText="Delete"
          ctaConfirmSubmit={deleteCandidatesHandler.deleteCandidates}
          ctaConfirmSubmitDataTestid="button-confirm-delete-vehicles"
          onClose={closeModalAndResetRejected}
        >
          {rejected > 0
            ? `An error occured while deleting ${rejected} vehicle
          candidate${rejectedPlural}. Please try again`
            : `Are you sure you want to delete ${selectedCandidates.length} vehicle
          candidate${candidatesPluralLowercase} from this solution?`}
        </Modal>
      )}
      {modal === 'duplicate-candidates' && (
        <Modal
          size="xs"
          disabled={isLoading}
          header={`Duplicate vehicle candidate${candidatesPluralLowercase}`}
          ctaConfirmText="Duplicate"
          ctaConfirmSubmit={duplicateCandidatesHandler.duplicateCandidates}
          variant="primary"
          ctaConfirmSubmitDataTestid="button-confirm-duplicate-vehicles"
          onClose={closeModalAndResetRejected}
        >
          {rejected > 0
            ? `An error occured while duplicating ${rejected} vehicle
          candidate${rejectedPlural}. Please try again`
            : `Are you sure you want to duplicate ${selectedCandidates.length} vehicle
          candidate${candidatesPluralLowercase}?`}
        </Modal>
      )}
      {solutionQuery.isSuccess && (
        <>
          <BackLink url="/" className="mb-spacing-64">
            All solutions
          </BackLink>
          <SolutionPageHeader
            solutionName={solutionQuery.data.Name}
            description={[
              `Add vehicle candidates by evaluating existing chassis or define them manually. Assess candidates to adjust configuration, operation, and understand electrification feasibility.`,
            ]}
            cta={
              isSuccess && (
                <Button
                  onClick={addVehicle?.activateAddVehicleDrawer}
                  text="Add candidate"
                  className="mr-spacing-4"
                  dataTestid="button-new-candidate"
                  size="md"
                  disabled={!!selectedMode}
                  variant={candidatesHandler.filteredCandidates?.length ? 'secondary' : 'primary'}
                />
              )
            }
          >
            {solutionQuery.data.transformed.solutionNamePresentation}
          </SolutionPageHeader>
          {panel === 'configure-vehicle-product' && (
            <ConfigureVehicleProduct
              className="mt-spacing-64"
              selectedVehicles={selectedVehicles}
              onClose={closePanel}
              changeVehicleLoading={vehiclesQuery.isLoading || configureVehicleProductHandler.isLoading}
              onConfigureVehicleProduct={configureVehicleProductHandler.onConfigureVehicleProduct}
              highestDailyDistanceFromSelectedCandidates={highestDailyDistanceFromSelectedCandidates}
              highestGtwFromSelectedCandidates={highestGtwFromSelectedCandidates}
            />
          )}
          {panel === 'setup-pto' && getPtoDevicesQuery.data && (
            <SetupPto className="mt-spacing-64" setupPtoHandler={setupPtoHandler} />
          )}
          {candidatesHandler.isSuccess && solutionHasVehicles && (
            <>
              <ActionLayout
                actionLayoutRef={actionLayoutRef}
                className={panel ? 'mt-spacing-24' : 'mt-spacing-72'}
                left={
                  <>
                    {selectedMode ? (
                      <>
                        {selectedMode === 'setup-pto' && !panel && (
                          <div className="tds-u-mb2">
                            <TdsMessage
                              variant="information"
                              header="Select the vehicle candidates you would like to setup"
                              minimal
                            />
                          </div>
                        )}
                        <div className="flex">
                          <Button
                            disabled={Boolean(panel) || selectedCandidates.length === 0 || isLoading}
                            variant={selectedMode === 'delete-candidates' ? 'danger' : 'primary'}
                            text={
                              selectedMode === 'delete-candidates'
                                ? 'Delete'
                                : selectedMode === 'setup-pto'
                                  ? 'Setup PTO'
                                  : 'Confirm'
                            }
                            dataTestid="button-confirm-candidates-action"
                            size="sm"
                            onClick={handleManageAction}
                          />
                          <Button
                            text="Close"
                            variant="secondary"
                            className="ml-spacing-16"
                            size="sm"
                            onClick={closeAction}
                          />
                        </div>
                        <div className="flex items-center tds-u-mt2">
                          <TotalCount
                            count={selectedCandidates.length}
                            entityName={`CANDIDATE${candidatesPluralUppercase} SELECTED`}
                            autoPlural={false}
                          />
                          {!showOnlySelectedCandidates && (
                            <Checkbox
                              label="Select All"
                              value="select-all"
                              className="ml-spacing-16"
                              dataTestid="checkbox-select-all-candidates"
                              onChange={handleSelectAllExisting}
                              disabled={isLoading}
                              checked={allCandidatesIsSelected}
                            />
                          )}
                        </div>
                      </>
                    ) : (
                      <TotalCount
                        className="tds-u-mt2"
                        count={candidatesHandler.filteredCandidates?.length || 0}
                        entityName="VEHICLE CANDIDATE"
                      />
                    )}
                  </>
                }
                right={
                  <div className="flex items-center">
                    <CandidatesFilterToggle candidatesHandler={candidatesHandler} />
                    <PopoverMenu
                      show={showActionMenu}
                      options={manageCandidatesPopoverOptions}
                      hidePopoverMenu={() => setShowActionMenu(false)}
                      toggle={
                        <ButtonAlt
                          tdsIcon="kebab"
                          dataTestid="button-toggle-candidates-action-menu"
                          tdsIconSize={30}
                          style={{ height: '40px' }}
                          onClick={() => setShowActionMenu((prev) => !prev)}
                        />
                      }
                    />
                  </div>
                }
                below={<CandidatesFilterSelector candidatesHandler={candidatesHandler} />}
              />
            </>
          )}
          <Candidates
            candidatesHandler={candidatesHandler}
            vehicleSettingsHandler={vehicleSettingsHandler}
            dataTestid="solution-candidates-table"
            solutionId={solutionId}
            handleSelectAll={handleSelectAllShown}
            handleSelectRow={handleSelectRow}
            actionMode={selectedMode && !panel ? 'multiselect' : 'expandable-rows'}
          />
        </>
      )}
    </div>
  );
};

export default SolutionDetail;
