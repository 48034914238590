import applicationDefaultValues from './applicationDefaultValues';
import { SetOperationalFactors } from '../types';
import { useCallback } from 'react';

interface Props {
  ensureInGtwRange: (value: number) => number;
  setDefaultOfSelected: React.Dispatch<React.SetStateAction<string>>;
  setOperationalFactors: SetOperationalFactors;
}

const useSetApplicationDefaultValues = ({ ensureInGtwRange, setDefaultOfSelected, setOperationalFactors }: Props) =>
  useCallback(
    (value: string) => {
      const applicationValue = applicationDefaultValues.find((appValue) => appValue.APPLICATION === value);

      setDefaultOfSelected(value);

      if (applicationValue) {
        setOperationalFactors((prev) => ({
          ...prev,
          genericDailyOdometerKm: applicationValue.AVG_DAILY_ODOMETER_MEDIAN,
          genericMaxGtwKg: ensureInGtwRange(applicationValue.AVG_GTW),
          avgSpeed: applicationValue.AVG_SPEED,
          cruisingSpeed: applicationValue.AVG_CRUISE_SPEED,
        }));
      }
    },
    [ensureInGtwRange, setDefaultOfSelected, setOperationalFactors],
  );

export default useSetApplicationDefaultValues;
