import { SortFunctions, useSortItems } from '@optimization/ssi-common';
import { useMemo } from 'react';
import {
  sortCreatedAsc,
  sortCreatedDesc,
  sortModifiedAsc,
  sortModifiedDesc,
  sortAssemblyDateAsc,
  sortAssemblyDateDesc,
  sortByLaunchPeriodAsc,
  sortByLaunchPeriodDesc,
  sortGenericDailyOdometerKmAsc,
  sortGenericDailyOdometerKmDesc,
  sortDailyRangeAsc,
  sortDailyRangeDesc,
  sortDailyRangePercentAsc,
  sortDailyRangePercentDesc,
  sortPerformanceStepAsc,
  sortPerformanceStepDesc,
  sortEnergyConsumptionAsc,
  sortEnergyConsumptionDesc,
  ListVehicleEnhanced,
} from '@optimization/sa-common';

const sortFunctions: SortFunctions = {
  chassisAssemblyDate: {
    asc: sortAssemblyDateAsc,
    desc: sortAssemblyDateDesc,
  },
  created: {
    asc: sortCreatedAsc,
    desc: sortCreatedDesc,
  },
  dailyRange: {
    asc: sortDailyRangeAsc,
    desc: sortDailyRangeDesc,
  },
  genericDailyOdometerKm: {
    asc: sortGenericDailyOdometerKmAsc,
    desc: sortGenericDailyOdometerKmDesc,
  },
  dailyRangePercent: {
    asc: sortDailyRangePercentAsc,
    desc: sortDailyRangePercentDesc,
  },
  date: {
    asc: sortModifiedAsc,
    desc: sortModifiedDesc,
  },
  launchPeriod: {
    asc: sortByLaunchPeriodAsc,
    desc: sortByLaunchPeriodDesc,
  },
  performanceStep: {
    asc: sortPerformanceStepAsc,
    desc: sortPerformanceStepDesc,
  },
  energyConsumption: {
    asc: sortEnergyConsumptionAsc,
    desc: sortEnergyConsumptionDesc,
  },
};

interface Props {
  enhancedVehicles: ListVehicleEnhanced[];
  selectedAxleDistances: string[];
  selectedBatteries: string[];
  selectedCabs: string[];
  selectedChassisNames: string[];
  selectedLaunchPeriods: string[];
  selectedPerformanceSteps: string[];
  selectedWheelConfigurations: string[];
  selectedChassisAdaptations: string[];
  sortType: string;
  sortDirection: 'asc' | 'desc';
}

const useFilteredAndSortedVehicles = ({
  enhancedVehicles,
  selectedAxleDistances,
  selectedBatteries,
  selectedCabs,
  selectedChassisNames,
  selectedLaunchPeriods,
  selectedPerformanceSteps,
  selectedWheelConfigurations,
  selectedChassisAdaptations,
  sortType,
  sortDirection,
}: Props) => {
  const filteredVehicles = useMemo(() => {
    if (enhancedVehicles.length) {
      return [...enhancedVehicles].filter((candidate) => {
        const foundByAxleDistance =
          selectedAxleDistances.includes(candidate.AxleDistanceMm?.toString() || '') || !selectedAxleDistances.length;

        const foundByBattery =
          selectedBatteries.includes(`${candidate.enhanced.basicTruck?.InstalledEnergyKWh || 0}`) ||
          !selectedBatteries.length;

        const foundByCab = selectedCabs.includes(candidate.Cab?.toString() || '') || !selectedCabs.length;

        const foundByChassisAdaptation =
          selectedChassisAdaptations.includes(candidate.ChassisAdaptation || '') || !selectedChassisAdaptations.length;

        const foundByChassisNumber =
          selectedChassisNames.includes(candidate.transformed.chassisIdPresentation) || !selectedChassisNames.length;

        const foundByLaunchPeriod =
          selectedLaunchPeriods.includes(candidate.transformed.launchPeriodPresentation || '') ||
          !selectedLaunchPeriods.length;

        const foundByPerformanceStep =
          selectedPerformanceSteps.includes(candidate.transformed.performanceStepPresentation) ||
          !selectedPerformanceSteps.length;

        const foundByWheelConfiguration =
          selectedWheelConfigurations.includes(candidate.WheelConfiguration || '') ||
          !selectedWheelConfigurations.length;

        return (
          foundByAxleDistance &&
          foundByBattery &&
          foundByCab &&
          foundByChassisAdaptation &&
          foundByChassisNumber &&
          foundByLaunchPeriod &&
          foundByPerformanceStep &&
          foundByWheelConfiguration
        );
      });
    }
  }, [
    enhancedVehicles,
    selectedAxleDistances,
    selectedBatteries,
    selectedCabs,
    selectedChassisAdaptations,
    selectedChassisNames,
    selectedLaunchPeriods,
    selectedPerformanceSteps,
    selectedWheelConfigurations,
  ]);

  return useSortItems<ListVehicleEnhanced>({
    items: filteredVehicles,
    sortFunctions,
    sortType,
    sortDirection,
  });
};

export default useFilteredAndSortedVehicles;
