import { FormValues } from '..';
import { TcoVehicleType } from '@optimization/sa-common/dist/types/backendModels';
import {
  financialEvaluationVehicleTypeOptions,
  TcoCalculationsV2VM,
  VehicleEnhanced,
  NET_EC_PARAM_DEFAULT,
} from '@optimization/sa-common';

export const financeOptions = [
  { value: 'Leasing', name: 'Leasing' },
  { value: 'Cash', name: 'Cash' },
];

export const convertFormValuesToResponse = (vehicle: VehicleEnhanced, formValues: FormValues) => {
  const response: TcoCalculationsV2VM = {
    Currency: formValues.currency,
    ShowInPresentationApp: vehicle.TcoCalculations?.ShowInPresentationApp ?? true,
    BevVehicle: {
      EngineType: TcoVehicleType[TcoVehicleType.Bev],
      FinancingType: financeOptions.find((x) => x.value === formValues.bevFinanceType)!.value,
      VehiclePrice: convertStringToNumber(formValues.bevVehiclePrice),
      InterestRate: convertStringToNumber(formValues.bevInterestRate),
      ResidualValue: convertStringToNumber(formValues.bevResidual),
      GovernmentSubsidies: convertStringToNumber(formValues.bevGovernmentSubsidies),
      EnergyCost: convertStringToNumber(formValues.bevEnergyCost),
      EnergyConsumption: vehicle.EcCalculations[`Summer-${NET_EC_PARAM_DEFAULT}`].TotalKWhPerKm,
      InsurancePerMonth: convertStringToNumber(formValues.bevInsuranceCost),
      RoadTollPerMonth: convertStringToNumber(formValues.bevRoadToll),
      TaxPerMonth: convertStringToNumber(formValues.bevTaxesCost),
      MonthlyMaintenance: convertStringToNumber(formValues.bevMaintenanceCost),
    },
    ComparisonVehicle: {
      EngineType: financialEvaluationVehicleTypeOptions.find((x) => x.value === formValues.comparisonEngineType)!.value,
      FinancingType: financeOptions.find((x) => x.value === formValues.comparisonFinanceType)!.value,
      VehiclePrice: convertStringToNumber(formValues.comparisonVehiclePrice),
      InterestRate: convertStringToNumber(formValues.comparisonInterestRate),
      ResidualValue: convertStringToNumber(formValues.comparisonResidual),
      GovernmentSubsidies: convertStringToNumber(formValues.comparisonGovernmentSubsidies),
      EnergyCost: convertStringToNumber(formValues.comparisonEnergyCost),
      EnergyConsumption: convertStringToNumber(formValues.comparisonEnergyConsumption),
      AdBlueCost: convertStringToNumber(formValues.comparisonAdBlueCost),
      AdBlueConsumption: convertStringToNumber(formValues.comparisonAdBlueConsumption),
      InsurancePerMonth: convertStringToNumber(formValues.comparisonInsuranceCost),
      RoadTollPerMonth: convertStringToNumber(formValues.comparisonRoadtoll),
      TaxPerMonth: convertStringToNumber(formValues.comparisonTaxesCost),
      MonthlyMaintenance: convertStringToNumber(formValues.comparisonMaintenanceCost),
    },
  };

  if (response.ComparisonVehicle?.VehiclePrice === 0) response.ComparisonVehicle = undefined;

  return response;
};

export const convertStringToNumber = (formValue: string) => {
  const numberValue = Number(formValue);
  if (isNaN(numberValue) || formValue === '') return 0;

  return numberValue;
};
