import getInitialElectrificationPlan from './getInitialElectrificationPlan';
import { ElectrificationPlanItem, ElectrificationPlanVehicle, TElectrificationPlan } from '../';
import { KeyedString } from '@optimization/ssi-common';
import { SetVehiclesByPerformanceStepByNumberOfYears } from './useVehiclesByPerformanceStep';
import { useCallback, useState } from 'react';
import { VehiclesByPerformanceStep } from '../';

const allElectrificationPlanItemsIsModified = (electrificationPlan: TElectrificationPlan) =>
  electrificationPlan.every((item) => item.modified);

const allElectrificationPlanItemsHasNoVehicles = (electrificationPlan: TElectrificationPlan) =>
  electrificationPlan.every((item) => Object.keys(item.vehicles).length === 0);

const markAllElectrificationPlanItemsAsUnmodified = (electrificationPlan: TElectrificationPlan) => {
  for (const item of electrificationPlan) {
    item.modified = false;
  }
};

const moveVehicle = ({
  result,
  toIndex,
  fromIndex,
  modifiedIndex,
  vehicleId,
  vehicle,
}: {
  result: TElectrificationPlan;
  toIndex: number;
  fromIndex: number;
  modifiedIndex: number;
  vehicleId: string;
  vehicle: ElectrificationPlanVehicle;
}): boolean => {
  if (
    !result[modifiedIndex].modified &&
    vehicleIsValidForElectrificationPlanItem({
      vehicle,
      electrificationPlanItem: result[toIndex],
    })
  ) {
    result[toIndex].vehicles[vehicleId] = {
      ...vehicle,
    };

    result[modifiedIndex].modified = true;

    delete result[fromIndex].vehicles[vehicleId];

    return true;
  }

  return false;
};

const vehicleIsValidForElectrificationPlanItem = ({
  vehicle,
  electrificationPlanItem,
}: {
  vehicle: ElectrificationPlanVehicle;
  electrificationPlanItem: ElectrificationPlanItem;
}) => Number(vehicle.minimumPerformanceStep) <= Number(electrificationPlanItem.performanceStep);

export type OnChangeSlider = ({
  numberOfVehicles,
  currentPerformanceStep,
}: {
  numberOfVehicles: number;
  currentPerformanceStep: string;
}) => void;

interface Props {
  vehiclesByPerformanceStep: VehiclesByPerformanceStep;
  minimumPerformanceStepByVehicle: KeyedString;
  electrificationPlanVehicleCount: number;
  setElectrificationPlan: React.Dispatch<React.SetStateAction<TElectrificationPlan>>;
  setVehiclesByPerformanceStepByNumberOfYears: SetVehiclesByPerformanceStepByNumberOfYears;
}

export const useOnChangeSlider = ({
  vehiclesByPerformanceStep,
  minimumPerformanceStepByVehicle,
  electrificationPlanVehicleCount,
  setElectrificationPlan,
  setVehiclesByPerformanceStepByNumberOfYears,
}: Props): OnChangeSlider => {
  // We need to know the last performance step in order to reset modified when the user selects another year slider
  const [, setLastPerformanceStep] = useState<string>();

  return useCallback(
    ({ numberOfVehicles, currentPerformanceStep }: { numberOfVehicles: number; currentPerformanceStep: string }) => {
      let electrificationPlan = getInitialElectrificationPlan({
        vehiclesByPerformanceStep,
        minimumPerformanceStepByVehicle,
      });

      if (electrificationPlan.length === 1) {
        // When a slider is dragged and there is only slider year we must automatically add a new slider to recieve those items
        setVehiclesByPerformanceStepByNumberOfYears(2);
        return;
      }

      setLastPerformanceStep((prevLastPerformanceStep) => {
        setElectrificationPlan((prevElectrificationPlan) => {
          const result = prevElectrificationPlan.map((item) => ({
            ...item,
            modified:
              // Modified is resetted each time the user selects a different slider
              prevLastPerformanceStep && prevLastPerformanceStep !== currentPerformanceStep ? false : item.modified,
            vehicles: Object.keys(item.vehicles).reduce<{ [vehicleId: string]: ElectrificationPlanVehicle }>(
              (acc, vehicleId) => ({
                ...acc,
                [vehicleId]: {
                  minimumPerformanceStep: item.vehicles[vehicleId].minimumPerformanceStep,
                },
              }),
              {},
            ),
          }));

          const currentIndex = result.findIndex((item) => item.performanceStep === currentPerformanceStep);

          if (currentIndex > -1) {
            const vehiclesDiff = numberOfVehicles - Object.keys(result[currentIndex].vehicles).length;

            if (vehiclesDiff) {
              let movedVehiclesCount = 0;

              const comingItems = result.filter((_, i) => i > currentIndex);
              const previousItems = result.filter((_, i) => i < currentIndex);

              let avoidInfiniteLoopIndex = 0;
              const avoidInfiniteLoopMaxIndex = electrificationPlanVehicleCount * electrificationPlan.length;

              if (vehiclesDiff > 0) {
                // INCREASING VEHICLES
                // Meaning moving vehicles to current year from other years

                let allComingItemsHasNoVehicles = false;

                while (movedVehiclesCount < vehiclesDiff && avoidInfiniteLoopIndex < avoidInfiniteLoopMaxIndex) {
                  if (comingItems.length && !allComingItemsHasNoVehicles) {
                    // Getting vehicles from coming years
                    for (let otherIndex = 0; otherIndex < result.length; otherIndex++) {
                      const allComingItemsIsModified = allElectrificationPlanItemsIsModified(comingItems);

                      if (allComingItemsIsModified) {
                        markAllElectrificationPlanItemsAsUnmodified(comingItems);
                      }

                      allComingItemsHasNoVehicles = allElectrificationPlanItemsHasNoVehicles(comingItems);

                      if (allComingItemsHasNoVehicles) {
                        // To go on with previous years when coming years has been emptied of vehicles
                        break;
                      }

                      if (otherIndex !== currentIndex && otherIndex > currentIndex) {
                        const vehicleIds = Object.keys(result[otherIndex].vehicles);

                        if (vehicleIds.length) {
                          for (const vehicleId of vehicleIds) {
                            if (movedVehiclesCount < vehiclesDiff) {
                              if (
                                moveVehicle({
                                  result,
                                  toIndex: currentIndex,
                                  fromIndex: otherIndex,
                                  modifiedIndex: otherIndex,
                                  vehicle: result[otherIndex].vehicles[vehicleId],
                                  vehicleId,
                                })
                              ) {
                                movedVehiclesCount++;
                                break;
                              }
                            } else {
                              break;
                            }
                          }
                        } else {
                          // When the year contains zero vehicles, we must set this in order to go on with the next year
                          result[otherIndex].modified = true;
                        }
                      }
                    }
                  } else if (previousItems.length) {
                    // Getting vehicles from previous years
                    for (let otherIndex = result.length - 1; otherIndex >= 0; otherIndex--) {
                      const allPreviousItemsIsModified = allElectrificationPlanItemsIsModified(previousItems);

                      if (allPreviousItemsIsModified) {
                        markAllElectrificationPlanItemsAsUnmodified(previousItems);
                      }

                      if (otherIndex !== currentIndex && otherIndex < currentIndex) {
                        const vehicleIds = Object.keys(result[otherIndex].vehicles);

                        if (vehicleIds.length) {
                          for (const vehicleId of vehicleIds) {
                            if (movedVehiclesCount < vehiclesDiff) {
                              if (
                                moveVehicle({
                                  result,
                                  toIndex: currentIndex,
                                  fromIndex: otherIndex,
                                  modifiedIndex: otherIndex,
                                  vehicle: result[otherIndex].vehicles[vehicleId],
                                  vehicleId,
                                })
                              ) {
                                movedVehiclesCount++;
                                break;
                              }
                            } else {
                              break;
                            }
                          }
                        } else {
                          // When the year contains zero vehicles, we must set this in order to go on with the next year
                          result[otherIndex].modified = true;
                        }
                      }
                    }
                  }

                  avoidInfiniteLoopIndex++;
                }
              } else {
                // DECREASING VEHICLES
                // Meaning moving vehicles to other years from current year
                const vehiclesDiffDecrease = vehiclesDiff * -1;

                while (
                  movedVehiclesCount < vehiclesDiffDecrease &&
                  avoidInfiniteLoopIndex < avoidInfiniteLoopMaxIndex
                ) {
                  if (comingItems.length) {
                    // Moving vehicles to coming years
                    for (let otherIndex = 0; otherIndex < result.length; otherIndex++) {
                      const allComingItemsIsModified = allElectrificationPlanItemsIsModified(comingItems);

                      if (allComingItemsIsModified) {
                        markAllElectrificationPlanItemsAsUnmodified(comingItems);
                      }

                      if (otherIndex !== currentIndex && otherIndex > currentIndex) {
                        for (const vehicleId of Object.keys(result[currentIndex].vehicles)) {
                          if (movedVehiclesCount < vehiclesDiffDecrease) {
                            if (
                              moveVehicle({
                                result,
                                toIndex: otherIndex,
                                fromIndex: currentIndex,
                                modifiedIndex: otherIndex,
                                vehicle: result[currentIndex].vehicles[vehicleId],
                                vehicleId,
                              })
                            ) {
                              movedVehiclesCount++;
                              break;
                            }
                          } else {
                            break;
                          }
                        }
                      }
                    }
                  } else if (previousItems.length) {
                    // Moving vehicles to previous years
                    for (let otherIndex = result.length - 1; otherIndex >= 0; otherIndex--) {
                      const allPreviousItemsIsModified = allElectrificationPlanItemsIsModified(previousItems);

                      if (allPreviousItemsIsModified) {
                        markAllElectrificationPlanItemsAsUnmodified(previousItems);
                      }

                      if (otherIndex !== currentIndex && otherIndex < currentIndex) {
                        for (const vehicleId of Object.keys(result[currentIndex].vehicles)) {
                          if (movedVehiclesCount < vehiclesDiffDecrease) {
                            if (
                              moveVehicle({
                                result,
                                toIndex: otherIndex,
                                fromIndex: currentIndex,
                                modifiedIndex: otherIndex,
                                vehicle: result[currentIndex].vehicles[vehicleId],
                                vehicleId,
                              })
                            ) {
                              movedVehiclesCount++;
                              break;
                            }
                          } else {
                            break;
                          }
                        }
                      }
                    }
                  }

                  avoidInfiniteLoopIndex++;
                }
              }
            } else {
              return prevElectrificationPlan;
            }
          }

          return result;
        });

        return currentPerformanceStep;
      });
    },
    [
      vehiclesByPerformanceStep,
      minimumPerformanceStepByVehicle,
      electrificationPlanVehicleCount,
      setElectrificationPlan,
      setVehiclesByPerformanceStepByNumberOfYears,
    ],
  );
};

export default useOnChangeSlider;
